import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import cls from 'classnames';
import { message } from 'antd';
import css from './Reschedule.module.css';
import { useQuery, useMutation } from '@apollo/client';
import { getTimeSlotsQuery, rescheduleVisitMutation } from 'gql';
import { timeSlotsToDateObject } from 'common_repo/utils/helpers';
import { Calendar, Button, Label, timeSlotsFilterHelper, cssText as text } from '_fsd/shared';
import { useVets } from '_fsd/entities/vet';
import { TimeslotCalendar as Timeslot } from './Timeslot';
import { Providers } from './Providers';
import petSvg from 'assets/icons/pet.svg';
import { FilterOption } from './FilterOption';

const DATE_FORMAT_FULL = 'YYYY-MM-DD';

export const Reschedule = (props) => {
  const {
    timeslotActions,
    filters,
    theme,
    displayFooter,
    appointment,
    close,
    currentAddress,
    isVetAdmin
  } = props;
  const { data: vets = [] } = useVets();
  const [isLoading, setLoading] = useState(false);

  const filtersQuery = useMemo(() => {
    const query = {
      vetIds: filters.selectedVets
    };
    if (!filters.displayTSNotInArea) {
      query.address = appointment?.address || currentAddress;
    }
    return query;
  }, [filters.displayTSNotInArea, filters.selectedVets]);
  const { data: { getTimeSlots: timeslotsData } = {}, loading } = useQuery(getTimeSlotsQuery, {
    variables: { record: filtersQuery },
    fetchPolicy: 'no-cache'
  });

  const [dates, setDates] = useState({});

  const timeslotsInDate = useMemo(() => {
    if (!loading) {
      let datesRaw = {};
      if (!timeslotsData || !timeslotsData.length) {
        datesRaw = {};
      } else if (filters.displayFullTS) {
        datesRaw = timeSlotsToDateObject({
          timeslots: timeslotsData,
          isForVet: true,
          isForAdminCalendar: isVetAdmin,
          displayLocked: filters.displayLocked
        });
      } else {
        datesRaw = timeSlotsToDateObject({
          timeslots: timeSlotsFilterHelper(timeslotsData),
          isForAdminCalendar: isVetAdmin,
          isForVet: true,
          displayLocked: filters.displayLocked
        });
      }
      const date = timeslotActions.calendarDate.format(DATE_FORMAT_FULL);
      const filtered = datesRaw[date] || [];
      if (!filtered.some((ts) => ts.uid === timeslotActions.activeTimeslot?.uid)) {
        timeslotActions.setActiveTimeslot();
      }
      setDates(datesRaw);
      return filtered;
    }
    return [];
  }, [
    timeslotActions.calendarDate,
    loading,
    filters.displayLocked,
    filters.displayFullTS,
    timeslotsData
  ]);

  const [reschedule] = useMutation(rescheduleVisitMutation);
  const rescheduleVisit = () => {
    setLoading(true);
    reschedule({
      variables: {
        record: {
          date: moment(timeslotActions.calendarDate).format('YYYY-MM-DD'),
          appointmentId: appointment.uid,
          timeSlotId: timeslotActions.activeTimeslot?.uid
        }
      },
      refetchQueries: ['getCalendarEvents', 'getTimeSlots', 'getAppointmentsByDate']
    })
      .then(() => {
        setLoading(false);
        message.success('Appointment rescheduled successfully');
        close();
      })
      .catch((e) => {
        message.error(e.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!timeslotActions.activeTimeslot) {
      timeslotActions.setActiveTimeslot(timeslotsInDate[0]);
    }
  }, [timeslotsInDate]);

  return (
    <>
      <div className={cls('flex-row', css.content, { [css.noPadding]: theme === 'light' })}>
        <div className={cls('flex-column', css.left_column)}>
          <div className={cls('flex-column', css.checkboxes)}>
            <span className={cls(css.filters, text.s16w6l18)}>Filters</span>
            <FilterOption
              disabled={isLoading}
              value={filters.displayFullTS}
              setValue={filters.setFullTS}
              label="Show full time-slots"
            />
            <FilterOption
              disabled={isLoading}
              value={filters.displayLocked}
              setValue={filters.setDisplayLocked}
              label="Show locked time-slots"
            />
            <FilterOption
              disabled={isLoading}
              value={filters.displayTSNotInArea}
              setValue={filters.setDisplayTSNotInArea}
              label="Show time-slots not in the area"
            />
            {isVetAdmin ? null : (
              <FilterOption
                disabled={isLoading}
                value={filters.displayVets}
                setValue={filters.setDisplayVets}
                label="Show other caregivers"
              />
            )}
          </div>
          <div className={cls('flex-column', 'jc-sb')}>
            <Providers
              displayVets={filters.displayVets}
              isLoading={isLoading}
              vets={vets}
              selectedVets={filters.selectedVets}
              setSelectedVets={filters.setSelectedVets}
            />
          </div>
        </div>
        <div className={cls('flex-column', css.right_column)}>
          <Calendar
            theme={theme}
            open
            hideHeader
            holidays={Object.keys(dates).map((date) => ({ date: `${date} 12:00:00` }))}
            endYear={2}
            minDate={new Date()}
            startYear={new Date().getFullYear()}
            selected={timeslotActions.calendarDate.toDate()}
            onChange={(value) => !isLoading && timeslotActions.setCalendarDate(moment(value))}
          />
          <div className={cls(css.timeslots)}>
            <span className={cls(css.tsranges, text.s16w6l18)}>Appointment time slot</span>
            {timeslotsInDate.length ? (
              <div className={css.rows}>
                {timeslotsInDate.map((ts) => (
                  <Timeslot
                    theme={theme}
                    key={ts.uid}
                    timeslot={ts}
                    disabled={ts.uid === timeslotActions.defaultTimeslot?.uid || isLoading}
                    active={ts.uid === timeslotActions.activeTimeslot?.uid}
                    onClick={timeslotActions.setActiveTimeslot}
                  />
                ))}
              </div>
            ) : (
              !loading && (
                <div className={css.petIcon}>
                  <img src={petSvg} alt="bg" />
                  <Label className={css.labelNoTimeslots}>
                    There are no timeslots available for the selected date and filters
                  </Label>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {displayFooter ? (
        <div className={css.footer}>
          {!timeslotActions.activeTimeslot && (
            <div className={cls(css.error, text.s14w4l22)}>* Please select a time-slot</div>
          )}
          <Button
            type="primary"
            colorScheme="blue"
            disabled={
              !timeslotActions.activeTimeslot ||
              timeslotActions.activeTimeslot?.uid === timeslotActions.defaultTimeslot?.uid ||
              isLoading
            }
            onClick={rescheduleVisit}>
            Reschedule
          </Button>
        </div>
      ) : null}
    </>
  );
};
