import React, { useState, useRef } from 'react';
import { Select, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useFindWorkingAreas } from '_fsd/entities';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { TagEditable, useOutsideClick } from '_fsd/shared';
import css from './WorkingAreasSelectMultiple.module.css';
import cls from 'classnames';

export const WorkingAreasSelectMultiple = ({ values, onSelect, placeholder, onEdit }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [search, setSearch] = useState('');
  const [active, setActive] = useState(false);
  const { current: onSearch } = useRef(debounce(setSearch, 300));
  const { data, loading } = useFindWorkingAreas({
    record: {
      name: search
    },
    skip: 0,
    take: 1000,
    isSaved: true,
    order: {
      [search ? 'name' : 'updatedAt']: search ? 'ASC' : 'DESC'
    }
  });

  useOutsideClick(
    ref,
    () => {
      setActive(false);
    },
    css.selectPopup
  );

  const handleSelect = (_, v) => {
    onSelect([...(values || []), v]);
  };
  const onDeselect = (v) => {
    onSelect(values.filter((area) => area.uid !== v));
  };
  const selectedValues = values?.map((v) => ({ label: v.name, value: v.uid }));

  return (
    <div ref={ref}>
      <Input
        className={css.input}
        placeholder={placeholder || t('settings_page.service_area.editor_zip.title')}
        loading={loading}
        onChange={(v) => onSearch(v.currentTarget.value)}
        onClick={() => setActive(true)}
        addonAfter={
          <div
            onClick={() => setActive(!active)}
            className={cls(css.downIcon, { [css.downIconReverse]: active })}>
            <DownOutlined />
          </div>
        }
      />
      <Select
        className={css.selectInput}
        popupClassName={css.selectPopup}
        open={active}
        value={selectedValues}
        mode="multiple"
        fieldNames={{ label: 'name', value: 'uid' }}
        style={{ width: '100%' }}
        defaultActiveFirstOption={false}
        filterOption={false}
        options={data?.data || []}
        loading={loading}
        onDeselect={onDeselect}
        onSelect={handleSelect}
        placeholder={t('settings_page.service_area.editor_zip.title')}
        showSearch
      />
      <div className={css.row}>
        {values.map((area) => (
          <TagEditable
            hideColorBox
            color={'#545B6814'}
            key={area.uid}
            onClose={() => onDeselect(area.uid)}>
            {area.name}
          </TagEditable>
        ))}
      </div>
    </div>
  );
};
