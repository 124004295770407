import React, { useState, useRef } from 'react';
import { Select, Input } from 'antd';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { TagEditable, useOutsideClick } from '_fsd/shared';
import { useZipCodes } from '_fsd/entities/zip';
import css from './ZipCodeSelectMultiple.module.css';

export const ZipCodeSelectMultiple = ({ values, onSelect }) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [zipSearch, setZipSearch] = useState('');
  const { data, loading } = useZipCodes({ variables: { zip: zipSearch } });
  const { current: onSearch } = useRef(debounce(setZipSearch, 300));

  const [active, setActive] = useState(false);

  useOutsideClick(
    ref,
    () => {
      setActive(false);
    },
    css.selectPopup
  );

  const handleSelect = (_, v) => {
    onSelect([...(values || []), v]);
  };
  const onDeselect = (v) => {
    onSelect(values.filter((zip) => zip.uid !== v));
  };
  const selectedValues = values?.map((v) => ({ label: v.zip, value: v.uid }));

  return (
    <div ref={ref}>
      <Input
        placeholder={t('settings_page.service_area.editor_zip.select_placeholder')}
        loading={loading}
        onChange={(v) => onSearch(v.currentTarget.value)}
        onClick={() => setActive(true)}
      />
      <Select
        className={css.selectInput}
        popupClassName={css.selectPopup}
        open={active}
        value={selectedValues}
        mode="multiple"
        fieldNames={{ label: 'zip', value: 'uid' }}
        style={{ width: '100%' }}
        defaultActiveFirstOption={false}
        filterOption={false}
        options={data || []}
        loading={loading}
        onDeselect={onDeselect}
        onSelect={handleSelect}
        placeholder={t('settings_page.service_area.editor_zip.select_placeholder')}
        showSearch
      />
      <div className={css.row}>
        {values?.map((zip) => (
          <TagEditable
            hideColorBox
            color={'#545B6814'}
            key={zip.uid}
            onClose={() => onDeselect(zip.uid)}>
            {zip.zip}
          </TagEditable>
        ))}
      </div>
    </div>
  );
};
