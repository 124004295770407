import React from 'react';
import { AntDesignOutlined } from '@ant-design/icons';
import css from './IntegrationIcon.module.css';

export const IntegrationIcon = ({ icon, bgColor, disabled }) => (
  <div
    className={disabled ? css.imageWrapperDisabled : css.imageWrapper}
    style={{ background: icon ? 'none' : bgColor }}>
    {icon ? (
      <img src={icon} alt="integration icon" />
    ) : (
      <AntDesignOutlined className={css.iconAntDesignOutlined} />
    )}
  </div>
);
