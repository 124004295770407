const { google } = window;

export const attachSearchToGMap = (inputRef, map, disable) => {
  if (!disable) {
    const input = inputRef.current;
    const autocomplete = new google.maps.places.Autocomplete(input, {
      fields: ['place_id', 'geometry', 'name']
    });

    autocomplete.bindTo('bounds', map);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
      input.style.display = 'block';
    });
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        return;
      }
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(10);
      }
    });
  }
};
