import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ReactComponent as MapPreviewIcon } from 'assets/icons/map-preview.svg';
import { Button, cssText } from '_fsd/shared';
import cls from 'classnames';
import css from './WorkingAreaTile.module.css';
import { useTranslation } from 'react-i18next';

export const WorkingAreaTile = ({ data, onEdit, onRemove }) => {
  const { t } = useTranslation();
  const areas = [...data.polygons?.map((p) => p.name), ...data.zipCodes?.map((z) => z.zip)].join(
    ', '
  );

  return (
    <div className={css.tile} onClick={() => onEdit(data)}>
      <div className={css.img}>
        {data.thumbnail ? <img src={data.thumbnail} alt="map" /> : <MapPreviewIcon />}
      </div>
      <div className={css.container}>
        <div className={css.name_container}>
          <span className={cls(cssText.s14w4l18, css.name)}>{data.name || 'Area'}</span>
        </div>
        <div className={cls(css.areas_container)}>
          <span className={cssText.s12w4l22}>{t('settings_page.service_area.tile.areas')}</span>
          <span className={cssText.s12w7l22}>{areas}</span>
        </div>
      </div>
      <div className={css.controls}>
        <Button icon={<EditOutlined />} onClick={() => onEdit(data)} />
        <div className={css.delimiter} />
        <Button
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            onRemove(data);
          }}
        />
      </div>
    </div>
  );
};
