import React, { useEffect, useMemo, useState, useCallback } from 'react';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import { VetStatus } from 'constants/enums';
import { Row, Col, Radio } from 'antd';
import VetCalendar from 'components/vet-components/calendar/VetCalendar';
import NewAppointmentModal from '../new-appointment-modal/NewAppointmentModal';
import LimitReachedWarning from '../limit-reached/LimitReachedWarning';
import NewClientModal from '../new-appointment-modal/NewClientModal';
import CalendarLink from './calendar-link/CalendarLink';
import Appointments from './appointments/Appointments';
import useCalendar from '../../calendar/useCalendar';
import Timeslots from './timeslots/Timeslots';
import useRoutes from './useRoutes';
import { viewType as VIEW_TYPE } from './types';
import css from './Routes.module.css';

import { routeStatus as Route } from 'components/timeline/appointments/useTimelineAppointments';
import ModalOrComponent from 'components/modal-or-component/ModalOrComponent';
import EditAppointmentAddressModal from '../edit-appointment-address-modal/EditAppointmentAddressModal';

import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';
import { Appointments as AppointmentsNew } from '_fsd/widgets/vet-appointments';
import { Skeleton } from 'antd';

const emptySelectedAppointment = {
  addPatient: null,
  duration: null,
  cancel: null,
  reschedule: null,
  eta: null
};

const Routes = () => {
  const { isMobile } = mediaQueriesList();
  const { sendRequestCode, checkExistingUser } = useCalendar();

  const [current, setCurrent] = useState(0);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [routeId, setRouteId] = useState(null);
  const [petsCount, setPetsCount] = useState(0);
  const [routeStatus, setRouteStatus] = useState('');
  const [isNewClientModalOpen, setIsNewClientModalOpen] = useState(false);
  const [isAppointmentsModalOpen, setIsAppointmentsModalOpen] = useState(false);
  const [isNewAppointmentsModalOpen, setIsNewAppointmentsModalOpen] = useState(false);

  const [viewType, setViewType] = useState(VIEW_TYPE.LIST_VIEW);

  const {
    vet,
    loading,
    calendarDate,
    refetchAuthVet,
    selectedTimeslot,
    spotCalendarDate,
    appointmentsData,
    setSelectedTimeslot,
    setAppointmentsData,
    selectedDateTimeSlots,
    changeVisitOrderHandler,
    setSelectedDateTimeSlots,
    appointmentAddressToEdit,
    setAppointmentAddressToEdit,
    getAppointmentsByTimeslotUid
  } = useRoutes(routeId);

  const toggleView = (view) => {
    setViewType(view);
  };

  const startPoint = useMemo(() => {
    if (vet?.uid) {
      const address = vet?.user?.userSettings?.find(
        (s) => s?.setting?.description === 'DEFAULT_START_POINT'
      )?.itemValue;

      if (!address) return {};

      return JSON.parse(address);
    }
  }, [vet]);

  const endPoint = useMemo(() => {
    if (vet?.uid) {
      const address = vet?.user?.userSettings?.find(
        (s) => s?.setting?.description === 'DEFAULT_END_POINT'
      )?.itemValue;

      if (!address) return {};

      return JSON.parse(address);
    }
  }, [vet]);

  const clientLimit = useMemo(() => {
    if (vet?.uid) {
      const address = vet?.user?.userSettings?.find(
        (s) => s?.setting?.description === 'CLIENTS_PER_HOUR'
      )?.itemValue;

      if (!address) return {};

      return JSON.parse(address);
    }
  }, [vet]);

  const selectedWorkingArea = useMemo(() => {
    if (selectedDateTimeSlots.length && selectedTimeslot) {
      return selectedDateTimeSlots.find((s) => s.uid === selectedTimeslot);
    }
  }, [selectedDateTimeSlots, selectedTimeslot]);

  const openNewClientModal = useCallback(() => {
    setIsNewClientModalOpen(true);
    setIsNewAppointmentsModalOpen(false);
  }, []);

  useEffect(() => {
    if (appointmentsData && appointmentsData?.length) {
      const route = appointmentsData?.[0]?.appointmentRoute?.route;
      setSelectedRoute(route);
      setRouteId(route?.uid);
      setRouteStatus(route?.status);
    }
  }, [appointmentsData]);

  const handleNewAppointmentModalClose = useCallback(() => {
    setIsNewAppointmentsModalOpen(false);
    setCurrent(0);
  }, []);

  useEffect(() => {
    if (appointmentsData && appointmentsData?.length) {
      const route = appointmentsData?.[0]?.appointmentRoute?.route;
      setSelectedRoute(route);
      setRouteId(route?.uid);
      setRouteStatus(route?.status);
    }
  }, [appointmentsData]);

  const registerOrAdjustment = useMemo(() => {
    return (
      routeStatus?.toLowerCase() === Route.REGISTER ||
      routeStatus?.toLowerCase() === Route.ADJUSTMENT
    );
  }, [routeStatus]);

  useEffect(() => {
    if (appointmentsData && !!appointmentsData.length) {
      let pets = 0;
      appointmentsData.forEach((app) => {
        pets += app.pets.length;
      });
      setPetsCount(pets);
    }
  }, [appointmentsData]);

  const changeStep = useCallback(
    (step) => {
      if (step < current) setCurrent(step);
    },
    [current]
  );

  const [selectedAppointment, setSelectedAppointment] = useState(emptySelectedAppointment);

  return (
    <Skeleton loading={loading} active>
      {vet && (
        <Row gutter={12} className={css.container}>
          <Col span={24} className="routes-left-column">
            <CalendarLink vet={vet} />
          </Col>
          <Col xs={24} lg={8} className="routes-left-column">
            <VetCalendar
              vetId={vet?.uid}
              timeSlots={vet?.timeSlots}
              calendarDate={calendarDate}
              fullTimeSlots={vet?.fullTimeSlots}
              setCalendarDate={spotCalendarDate}
              setSelectedTimeslot={setSelectedTimeslot}
              setSelectedDateTimeSlots={setSelectedDateTimeSlots}
              setAppointmentsData={setAppointmentsData}
            />
          </Col>

          {vet?.status?.toLowerCase() === VetStatus.LIMIT_REACHED ? (
            <Col xs={24} lg={16}>
              <LimitReachedWarning />
            </Col>
          ) : (
            <>
              <Col xs={24} lg={16}>
                <Timeslots
                  vet={vet}
                  setIsAppointmentsModalOpen={setIsAppointmentsModalOpen}
                  setSelectedDateTimeSlots={setSelectedDateTimeSlots}
                  registerOrAdjustment={registerOrAdjustment}
                  setSelectedTimeslot={setSelectedTimeslot}
                  timeslotsData={selectedDateTimeSlots}
                  appointmentsData={appointmentsData}
                  spotCalendarDate={spotCalendarDate}
                  refetchAuthVet={refetchAuthVet}
                  calendarDate={calendarDate}
                  clientLimit={clientLimit}
                  startPoint={startPoint}
                  endPoint={endPoint}
                />
              </Col>

              <ModalOrComponent
                // showModal={isMobile}
                isOpen={isAppointmentsModalOpen}
                onClose={() => setIsAppointmentsModalOpen(false)}>
                <Col span={24} className="routes-left-column">
                  {isMobile && (
                    <Row>
                      <Col span={24}>
                        <CloseIcon
                          className="close-modal-icon"
                          onClick={() => setIsAppointmentsModalOpen(false)}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={24}>
                      <AppointmentsNew
                        onClickAddress={(app) => setAppointmentAddressToEdit(app)}
                        selectedTimeslot={selectedDateTimeSlots.find(
                          (t) => t.uid === selectedTimeslot
                        )}
                        vet={vet}
                        routeId={routeId}
                        timeSlotId={selectedTimeslot}
                        selectedRoute={selectedRoute}
                        date={calendarDate}
                        setSelectedAppointment={setSelectedAppointment}
                        routeStatus={routeStatus}
                        setIsNewAppointmentsModalOpen={setIsNewAppointmentsModalOpen}
                      />
                      <Appointments
                        selectedAppointment={selectedAppointment}
                        setSelectedAppointment={setSelectedAppointment}
                        viewType={viewType}
                        setIsNewAppointmentsModalOpen={setIsNewAppointmentsModalOpen}
                        getAppointmentsByTimeslotUid={getAppointmentsByTimeslotUid}
                        setAppointmentAddressToEdit={setAppointmentAddressToEdit}
                        setIsAppointmentsModalOpen={setIsAppointmentsModalOpen}
                        changeVisitOrderHandler={changeVisitOrderHandler}
                        houseHolds={appointmentsData?.length || 0}
                        selectedTimeslot={selectedDateTimeSlots.find(
                          (t) => t.uid === selectedTimeslot
                        )}
                        selectedTimeslotId={selectedTimeslot}
                        selectedRoute={selectedRoute}
                        appointmentsData={appointmentsData}
                        fullTimeSlots={vet?.fullTimeSlots}
                        appointmentPets={petsCount}
                        calendarDate={calendarDate}
                        timeSlots={vet?.timeSlots}
                        routeStatus={routeStatus}
                        vetId={vet?.uid}
                        vet={vet}
                      />
                    </Col>
                  </Row>
                </Col>
              </ModalOrComponent>
            </>
          )}
        </Row>
      )}

      {isNewAppointmentsModalOpen && (
        <NewAppointmentModal
          vet={vet}
          current={current}
          changeStep={changeStep}
          setCurrent={setCurrent}
          calendarDate={calendarDate}
          selectedTimeslot={selectedTimeslot}
          openNewClientModal={openNewClientModal}
          onRequestClose={handleNewAppointmentModalClose}
          getAppointmentsByTimeslotUid={getAppointmentsByTimeslotUid}
        />
      )}
      {isNewClientModalOpen && (
        <NewClientModal
          vet={vet}
          calendarDate={calendarDate}
          sendRequestCode={sendRequestCode}
          selectedTimeslot={selectedTimeslot}
          checkExistingUser={checkExistingUser}
          selectedWorkingArea={selectedWorkingArea}
          onRequestClose={() => setIsNewClientModalOpen(false)}
          goBack={() => {
            setIsNewClientModalOpen(false);
            setIsNewAppointmentsModalOpen(true);
          }}
          getAppointmentsByTimeslotUid={getAppointmentsByTimeslotUid}
        />
      )}

      {appointmentAddressToEdit && (
        <EditAppointmentAddressModal
          getAppointmentsByTimeslotUid={() =>
            getAppointmentsByTimeslotUid(selectedTimeslot, calendarDate)
          }
          onRequestClose={() => setAppointmentAddressToEdit(null)}
          appointment={appointmentAddressToEdit}
        />
      )}
    </Skeleton>
  );
};

export default Routes;
