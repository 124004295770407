import React, { useState } from 'react';
import useColumns from '../outdate/useColumns';
import moment from 'moment/moment';
import css from './VetCaregivers.module.css';
import { cssText } from '_fsd/shared';
const { Search } = Input;
import { Table, Input } from 'antd';
import { useClinicVets } from '_fsd/entities/vet-admin';

const orderConverter = (orderType) => {
  if (orderType === 'ascend') {
    return 'ASC';
  } else if (orderType === 'descend') {
    return 'DESC';
  } else return null;
};

export const VetCaregiversPage = (props) => {
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState({
    registrationDate: 'ASC',
    fullName: null,
    email: null,
    phone: null
  });
  const { data: vets, refetch: refetchVets, loading } = useClinicVets({ search, order: sortBy });
  const columns = useColumns(refetchVets);
  const changeSort = (sorter) => {
    const sortObject = {};
    if (Array.isArray(sorter)) {
      for (let i = 0; i < sorter.length; i++) {
        sortObject[sorter[i].field] = orderConverter(sorter[i].order);
      }
      setSortBy((prev) => {
        return { ...prev, ...sortObject };
      });
    } else if (!!Object.keys(sorter).length) {
      const { field, order } = sorter;
      setSortBy((prev) => {
        return {
          registrationDate: null,
          fullName: null,
          email: null,
          phone: null,
          [field]: orderConverter(order)
        };
      });
    }
  };
  const dataSource = vets?.map((vet) => {
    return {
      key: vet?.uid,
      name: vet?.user?.firstName + ' ' + vet?.user?.lastName,
      userID: vet?.uid,
      registrationDate: moment(vet?.createdAt).format('DD/MM/YYYY'),
      email: vet?.user?.email,
      phone: vet?.user?.phonePrefix + vet?.user?.phoneNumber,
      country: vet?.user?.address?.country?.name,
      activeCustomers: vet?.activeCustomersCount,
      status: vet?.status,
      timeSlots: vet?.timeSlots,
      fullTimeSlots: vet?.fullTimeSlots,
      vetUserId: vet?.user?.uid,
      user: vet?.user
    };
  });

  return (
    <div className={css.container}>
      <div className={css.header}>
        <span className={cssText.s16h24w5}>Caregivers</span>
      </div>
      <Search
        placeholder="Search"
        onSearch={setSearch}
        style={{ maxWidth: '370px', marginBottom: '20px' }}
      />
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        onChange={(pagination, filters, sorter) => {
          changeSort(sorter);
        }}
      />
    </div>
  );
};
