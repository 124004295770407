import React from 'react';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import css from './IntegrationStatus.module.css';
import Popover from 'components/vet-admin/lib/popover/Popover';
import { INTEGRATION_STATUS } from '_fsd/entities/integration/types';

const PopoverContent = ({ messages }) => (
  <div className={css.popover}>
    {messages.map((m) => (
      <span key={m.id}>{m.message}</span>
    ))}
  </div>
);

export const IntegrationStatus = ({ status, messages }) => {
  return (
    <>
      {status === INTEGRATION_STATUS.ENABLED && (
        <CheckCircleFilled className={css.iconCheckCircleFilled} />
      )}
      {status === INTEGRATION_STATUS.DISABLED && (
        <Popover content={<PopoverContent messages={messages} />}>
          <ExclamationCircleFilled className={css.iconExclamationCircleFilled} />
        </Popover>
      )}
    </>
  );
};
