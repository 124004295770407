import { gql } from 'graphql.macro';
import { useMutation } from '@apollo/client';

export const useCreateWorkingArea = (options) => {
  return useMutation(query, {
    ...options,
    refetchQueries: ['findWorkingAreas', ...(options?.refetchQueries || [])]
  });
};

const query = gql`
  mutation createWorkingArea($record: CreateWorkingAreaRecord!) {
    workingArea: createWorkingArea(record: $record) {
      uid
      createdAt
      updatedAt
      name
      type
      thumbnail
      isSaved
      vetAdmin {
        uid
      }
      polygons {
        uid
        name
        area
      }
      zipCodes {
        uid
        zip
      }
    }
  }
`;
