import { useLocalizationContext } from 'common_repo';
import React, { useEffect, useRef, useState } from 'react';

import PInput from 'react-phone-input-2';

import { Container } from './components';

import 'react-phone-input-2/lib/material.css';
import { ErrorMessage } from '../error-message/components';

const PhoneInput = ({
  value,
  label,
  error,
  searchPlaceholder = 'Search',
  placeholder,
  disabled,
  hideLabel,
  onChange,
  isForVet,
  isPublic
}) => {
  const { countryCode } = useLocalizationContext();

  const [dropDownOpen, setDropDownOpen] = useState(false);

  const containerRef = useRef(null);

  const { rtl: isRtl } = useLocalizationContext();

  const handleClickOutside = (event) => {
    if (containerRef && !containerRef.current.contains(event.target)) {
      setDropDownOpen(false);
    }
  };
  const onClick = () => {
    if (!disabled) setDropDownOpen((curr) => !curr);
  };

  const handleChange = (value, country, e, formattedValue) =>
    onChange(`+${country?.dialCode || ''}`, value.slice(country?.dialCode?.length));
  const handleFocus = () => {
    setDropDownOpen(false);
  };

  useEffect(() => {
    const drop = document.querySelector('.selected-flag');
    document.addEventListener('mousedown', handleClickOutside);
    drop.addEventListener('click', onClick);

    return () => {
      drop.removeEventListener('click', onClick);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dropDownOpen) {
      setTimeout(() => {
        const selectedCountry = document.querySelector('.country-list');
        selectedCountry.scrollTop = selectedCountry.scrollTop + 51;
      }, 0);
    }
  }, [dropDownOpen]);

  console.log({ error });

  return (
    <Container
      ref={containerRef}
      dropDownOpen={dropDownOpen}
      hideLabel={hideLabel}
      error={error}
      disabled={disabled}
      isRtl={isRtl}
      isForVet={isForVet}
      isPublic={isPublic}>
      <PInput
        // onlyCountries={["ge", "us", "il"]}
        placeholder={placeholder}
        inputClass="phone-input"
        country={countryCode?.toLowerCase() || 'us'}
        value={value}
        default="us"
        enableTerritories={true}
        onChange={handleChange}
        disabled={disabled}
        specialLabel={label}
        enableSearch
        searchPlaceholder={searchPlaceholder}
        searchClass="search-class"
        disableSearchIcon
        onFocus={handleFocus}
      />
      {!isPublic && (
        <ErrorMessage isError={error} isForVet={isForVet}>
          {error}
        </ErrorMessage>
      )}
    </Container>
  );
};

export default PhoneInput;
