const KEY_DEFAULT_START_POINT = 'DEFAULT_START_POINT';

export const parseUserSettingStartPoint = (userSettings = {}) => {
  try {
    const settingItem = userSettings?.find(
      (setting) => setting?.setting?.description === KEY_DEFAULT_START_POINT
    );
    return JSON.parse(settingItem?.itemValue);
  } catch (e) {
    return {};
  }
};
