import React, { useRef, useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalizationContext } from 'common_repo';
import { toggleChat } from 'utils/helpers';
import { DASHBOARD, SHOP, VET_ROUTES, VET_ADMIN_ROUTES } from 'constants/client';
import useOutsideClickDetector from 'hooks/useOutsideClickDetector';
import { getPetsByOwnerQuery } from 'gql';
import { SubMenuPet } from 'components/common/sub-menu-pet/SubMenuPet';
import SingleMenuItem from '../single-menu-item/SingleMenuItem';

import { ReactComponent as HomeIcon } from 'assets/icons/home-orange.svg';
import { ReactComponent as PawIcon } from 'assets/icons/paw-orange.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat-orange.svg';
import { ReactComponent as QuestionMark } from 'assets/icons/question-mark.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as GooglePlay } from 'assets/icons/google-play.svg';
import { ReactComponent as AppStore } from 'assets/icons/app-store.svg';

import { ReactComponent as SvgClients } from 'assets/icons/icon-clients.svg';
import { ReactComponent as SvgCalendar } from 'assets/icons/icon-calendar.svg';
import { ReactComponent as SvgCaregivers } from 'assets/icons/icon-caregivers.svg';
import { ReactComponent as SvgRoutes } from 'assets/icons/icon-routes.svg';
import { ReactComponent as SvgSettings } from 'assets/icons/settings.svg';
import arrowDown from 'assets/icons/dropdown.svg';

import * as S from './components';
import { PetName, SubMenuLink } from '../common/sub-menu-pet/components';
import AuthContext from '../../contexts/AuthContext';
import { ShoppingFilled, AntDesignOutlined } from '@ant-design/icons';
import { Roles } from '../../constants/enums';

const SideMenu = ({ sidebarOpen, setSidebarOpen, isForVet, burgerRef }) => {
  const { t } = useTranslation();
  const { user, logout } = useContext(AuthContext);
  const isVetAdmin = user.role === Roles.VET_ADMIN;
  const isPetOwner = user.role === Roles.PET_OWNER;
  const isVet = user.role === Roles.VET;
  const history = useHistory();
  const location = useLocation();
  const { lang } = useLocalizationContext();
  const menuDefaultStatus = ['settings', 'profile'].includes(location.pathname.split('/')[1]);

  const [subMenuVisible, setSubMenuVisible] = useState(menuDefaultStatus);

  const subMenuToggler = () => {
    setSubMenuVisible((prev) => !prev);
  };

  const isPetsMenuActive = location.pathname.split('/')[1] === 'pets';

  const { data, loading } = useQuery(getPetsByOwnerQuery, {
    skip: isForVet && isVetAdmin
  });

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const sidebarRef = useRef(null);
  useOutsideClickDetector(sidebarRef, () => setSidebarOpen(false), burgerRef);

  const helpPage = useMemo(() => {
    return lang === 'heb'
      ? 'https://www.kumba.co.il/help-center'
      : 'https://www.kumba.co.il/en/help-center-en ';
  }, [lang]);

  const openHelpCenter = () =>
    window.open('https://kumba-help.freshdesk.com/support/home', '_blank');

  return (
    <S.Sidebar sidebarOpen={sidebarOpen} ref={sidebarRef}>
      <S.Navigation>
        <S.UserInfoAndNavWrapper>
          {isVetAdmin ? (
            <S.MenuItemsWrapper>
              <SingleMenuItem
                icon={<SvgCalendar />}
                setSidebarOpen={setSidebarOpen}
                to={VET_ADMIN_ROUTES.SCHEDULE}
                menuTitle={t('url.clinic_schedule')}
                isForVet={isForVet}
              />
              <SingleMenuItem
                icon={<SvgRoutes />}
                setSidebarOpen={setSidebarOpen}
                to={VET_ADMIN_ROUTES.APP_MANAGER}
                menuTitle={t('url.app_manager')}
                isForVet={isForVet}
              />
              <SingleMenuItem
                icon={<SvgCaregivers />}
                setSidebarOpen={setSidebarOpen}
                to={VET_ADMIN_ROUTES.CAREGIVERS}
                menuTitle={t('url.clinic_caregivers')}
                isForVet={isForVet}
              />
              <SingleMenuItem
                icon={<SvgClients />}
                setSidebarOpen={setSidebarOpen}
                to={VET_ADMIN_ROUTES.CLIENTS}
                menuTitle={t('url.clinic_clients')}
                isForVet={isForVet}
              />
              <SingleMenuItem
                icon={<SvgSettings />}
                subMenuVisible={subMenuVisible}
                onPress={subMenuToggler}
                menuTitle={t('url.settings')}
                isForVet={isForVet}
                isButton>
                <S.ExpandIcon src={arrowDown} alt="dropdown" expanded={subMenuVisible} />
              </SingleMenuItem>
              <S.SubMenuItemsWrapper
                subMenuVisible={subMenuVisible}
                isPetsMenuActive={isPetsMenuActive}>
                {subMenuVisible && (
                  <>
                    {/* <SubMenuLink to={VET_ADMIN_ROUTES.INTEGRATIONS}>*/}
                    {/*  <S.SubMenuItem>*/}
                    {/*    <PetName withoutImage>{t('url.integrations')}</PetName>*/}
                    {/*  </S.SubMenuItem>*/}
                    {/* </SubMenuLink>*/}
                    <SubMenuLink to={VET_ADMIN_ROUTES.SERVICE_AREA}>
                      <S.SubMenuItem>
                        <PetName withoutImage>{t('url.service_areas')}</PetName>
                      </S.SubMenuItem>
                    </SubMenuLink>
                  </>
                )}
              </S.SubMenuItemsWrapper>
            </S.MenuItemsWrapper>
          ) : null}
          {isVet ? (
            <S.MenuItemsWrapper>
              <SingleMenuItem
                icon={<SvgRoutes />}
                setSidebarOpen={setSidebarOpen}
                to={VET_ROUTES.ROUTES}
                menuTitle={t('url.app_manager')}
                isForVet={isForVet}
              />

              <SingleMenuItem
                icon={<SvgClients />}
                setSidebarOpen={setSidebarOpen}
                to={VET_ROUTES.CUSTOMERS}
                menuTitle={t('clients')}
                isForVet={isForVet}
              />

              <SingleMenuItem
                icon={<SvgSettings />}
                subMenuVisible={subMenuVisible}
                onPress={subMenuToggler}
                menuTitle={t('url.settings')}
                isForVet={isForVet}
                isButton>
                <S.ExpandIcon src={arrowDown} alt="dropdown" expanded={subMenuVisible} />
              </SingleMenuItem>

              <S.SubMenuItemsWrapper
                subMenuVisible={subMenuVisible}
                isPetsMenuActive={isPetsMenuActive}>
                {subMenuVisible && (
                  <>
                    <SubMenuLink to={VET_ROUTES.UPDATE_VET}>
                      <S.SubMenuItem>
                        <PetName>{t('profile')}</PetName>
                      </S.SubMenuItem>
                    </SubMenuLink>
                    <SubMenuLink to={VET_ROUTES.SETTINGS}>
                      <S.SubMenuItem>
                        <PetName>{t('url.settings')}</PetName>
                      </S.SubMenuItem>
                    </SubMenuLink>
                  </>
                )}
              </S.SubMenuItemsWrapper>

              {!isForVet && subMenuVisible && (
                <S.SubMenuItemsWrapper
                  subMenuVisible={subMenuVisible}
                  isPetsMenuActive={isPetsMenuActive}>
                  {loading && (
                    <S.Loaders>
                      <S.Loader>
                        <S.LoaderCircle />
                        <S.LoaderLine />
                      </S.Loader>
                      <S.Loader>
                        <S.LoaderCircle />
                        <S.LoaderLine />
                      </S.Loader>
                      <S.Loader>
                        <S.LoaderCircle />
                        <S.LoaderLine />
                      </S.Loader>
                    </S.Loaders>
                  )}
                  {data?.getPetsByOwner?.map(({ uid, avatarUrl, name, type }) => (
                    <SubMenuPet
                      key={uid}
                      petAvatar={avatarUrl}
                      petName={name}
                      petType={type?.name}
                      uid={uid}
                    />
                  ))}
                </S.SubMenuItemsWrapper>
              )}

              {isMobile && (
                <>
                  <SingleMenuItem
                    setSidebarOpen={setSidebarOpen}
                    to="#"
                    // icon={<ChatIcon />}
                    menuTitle={t('chat')}
                    onClick={(e) => toggleChat(e, history, location)}
                  />
                  <SingleMenuItem
                    setSidebarOpen={setSidebarOpen}
                    to="#"
                    // icon={<ChatIcon />}
                    menuTitle={t('help.center')}
                    onClick={openHelpCenter}
                  />
                  <SingleMenuItem
                    setSidebarOpen={setSidebarOpen}
                    // icon={<LogOutIcon />}
                    menuTitle={t('log.out')}
                    onClick={() => {
                      logout();
                    }}
                    isButton
                    isLogout
                  />
                </>
              )}
            </S.MenuItemsWrapper>
          ) : null}
          {isPetOwner ? (
            <S.MenuItemsWrapper>
              <SingleMenuItem
                setSidebarOpen={setSidebarOpen}
                to={DASHBOARD}
                icon={<HomeIcon />}
                menuTitle={t('home')}
              />

              <SingleMenuItem
                subMenuVisible={subMenuVisible}
                onPress={subMenuToggler}
                icon={<PawIcon />}
                menuTitle={t('pets')}
                isButton>
                <S.ExpandIcon src={arrowDown} alt="dropdown" expanded={subMenuVisible} />
              </SingleMenuItem>

              {subMenuVisible && (
                <S.SubMenuItemsWrapper
                  subMenuVisible={subMenuVisible}
                  isPetsMenuActive={isPetsMenuActive}>
                  {loading && (
                    <S.Loaders>
                      <S.Loader>
                        <S.LoaderCircle />
                        <S.LoaderLine />
                      </S.Loader>
                      <S.Loader>
                        <S.LoaderCircle />
                        <S.LoaderLine />
                      </S.Loader>
                      <S.Loader>
                        <S.LoaderCircle />
                        <S.LoaderLine />
                      </S.Loader>
                    </S.Loaders>
                  )}
                  {data?.getPetsByOwner?.map(({ uid, avatarUrl, name, type }) => (
                    <SubMenuPet
                      key={uid}
                      petAvatar={avatarUrl}
                      petName={name}
                      petType={type?.name}
                      uid={uid}
                    />
                  ))}
                </S.SubMenuItemsWrapper>
              )}

              <SingleMenuItem
                setSidebarOpen={setSidebarOpen}
                menuTitle={t('shop.label')}
                icon={<ShoppingFilled />}
                to={SHOP}
              />

              {isMobile && (
                <>
                  <SingleMenuItem
                    setSidebarOpen={setSidebarOpen}
                    to="#"
                    icon={<ChatIcon />}
                    menuTitle={t('chat')}
                    onClick={(e) => toggleChat(e, history, location)}
                  />
                  <a href={helpPage} target="_blank" rel="noreferrer">
                    <S.Help>
                      <QuestionMark />
                      <h2>{t('help')}</h2>
                    </S.Help>
                  </a>
                </>
              )}
            </S.MenuItemsWrapper>
          ) : null}
        </S.UserInfoAndNavWrapper>

        {isVet ? (
          <S.Footer>
            <S.FooterTitle>{t('download.app.to.manage.routes')}</S.FooterTitle>
            <S.StoresWrapper>
              <a
                href="https://play.google.com/store/apps/details?id=com.kumba.kumbavets"
                target="_blank"
                rel="noreferrer">
                <GooglePlay />
              </a>
              <a
                href="https://apps.apple.com/uy/app/kumba-vets/id1596611177"
                target="_blank"
                rel="noreferrer">
                <AppStore />
              </a>
            </S.StoresWrapper>
          </S.Footer>
        ) : null}
        {isMobile && (
          <SingleMenuItem
            setSidebarOpen={setSidebarOpen}
            icon={<LogOutIcon />}
            menuTitle={t('log.out')}
            onClick={() => {
              logout();
            }}
            isButton
            isLogout
          />
        )}
      </S.Navigation>
    </S.Sidebar>
  );
};

export default SideMenu;
