import * as React from 'react';
import { Breadcrumb } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '../../constants/client';
import { Roles } from '../../constants/enums';

const BreadCrumb = ({ role }) => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const activeItem = Object.keys(ROUTES)
    .find((key) => ROUTES[key] === match.path)
    .replace('_', ' ');

  const renderItem = {
    CUSTOMERS: 'CLIENTS',
    ROUTES: 'SCHEDULE'
  };

  const firstPageText =
    role === Roles.VET_ADMIN
      ? t('breadcrumb.admin').toUpperCase()
      : t('breadcrumb.home').toUpperCase();

  return (
    <div style={{ width: '100%' }}>
      <Breadcrumb>
        {activeItem === 'DASHBOARD' ? (
          <Breadcrumb.Item>{firstPageText}</Breadcrumb.Item>
        ) : (
          <>
            <Breadcrumb.Item>{firstPageText}</Breadcrumb.Item>
            <Breadcrumb.Item>{renderItem[activeItem] || activeItem}</Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumb;
