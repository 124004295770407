import useFontFaceObserver from 'use-font-face-observer';

const useFontsLoaded = () => {
  const isFontListLoaded = useFontFaceObserver([
    {
      family: `Inter`,
      weight: `400`
    },
    {
      family: `Inter`,
      weight: `500`
    },
    {
      family: `Inter`,
      weight: `600`
    },
    {
      family: `Inter`,
      weight: `700`
    }
  ]);

  return {
    isFontListLoaded
  };
};

export default useFontsLoaded;
