import { gql } from 'graphql.macro';
import { useMutation } from '@apollo/client';

export const useRemoveWorkingArea = (options) => {
  return useMutation(query, {
    ...options,
    refetchQueries: ['findWorkingAreas', ...(options?.refetchQueries || [])]
  });
};

const query = gql`
  mutation removeWorkingArea($id: String!) {
    removeWorkingArea(id: $id) {
      uid
    }
  }
`;
