import React from 'react';

export function useOutsideClick(ref, cb, sideSelectorClassName) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      const [element] = document.getElementsByClassName(sideSelectorClassName);
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (element ? !element.contains(event.target) : true)
      ) {
        cb();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
