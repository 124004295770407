import { useCallback, useState } from 'react';

const TAKE_LIMIT = 20;
const DEFAULT_FILTERS = {
  record: {},
  isSaved: true,
  skip: 0,
  take: TAKE_LIMIT,
  order: {}
};

const isFilterChanged = (newState, currentState) => {
  if (newState?.hasOwnProperty('isSaved') && newState.isSaved !== currentState.isSaved) return true;
  if (
    newState?.record?.hasOwnProperty('name') &&
    newState.record?.name !== currentState.record?.name
  )
    return true;
  // order: relevant only for infinity scroll
  if (newState?.hasOwnProperty('order') && newState.order !== currentState.order) return true;
};
export const useFilters = (defaultFilters = DEFAULT_FILTERS) => {
  const [filters, _setFilters] = useState({
    ...DEFAULT_FILTERS,
    ...defaultFilters
  });
  const setFilters = useCallback(
    (state) => {
      _setFilters((prev) => ({
        ...prev,
        ...state,
        skip: isFilterChanged(state, prev) ? 0 : state.skip
      }));
    },
    [_setFilters]
  );

  return [filters, setFilters];
};
