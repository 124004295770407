import React, { useEffect, useState, memo, useCallback, useContext } from 'react';
import { Form, Row, Col, Button, Typography, Popconfirm, Checkbox, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Modal, Button as ButtonFSD } from '_fsd/shared';

import useCreateVetTimeSlots from 'components/vet-components/create-vet-timeslots/useCreateVetTimeSlots';
import AddressAutocomplete from 'components/vet-components/address-autocomplete/AddressAutocomplete';
import SubmitButton from 'components/vet-components/vet-web-inputs/submit-button/SubmitBtn';
import DatePicker from 'components/vet-components/vet-web-inputs/date-picker/DatePicker';
import TimePicker from 'components/vet-components/vet-web-inputs/time-picker/TimePicker';
import Input from 'components/vet-components/vet-web-inputs/input/Input';
import { parseTimeSlotHour } from 'utils/helpers';

import { ReactComponent as UserIcon } from 'assets/icons/user-blue.svg';
import arrow from 'assets/icons/arrow-down-blue.svg';

import * as S from './components';
import './styles.css';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateWorkingArea } from '_fsd/widgets';
import { WorkingAreasMapView, WorkingAreasSelectMultiple } from '_fsd/features';
import css from './AddTimeslotModal.module.css';
import { getLatLng, parseUserSettingStartPoint } from '_fsd/entities/user';
import AuthContext from 'contexts/AuthContext';
const { Title } = Typography;

const AddTimeslotModal = ({
  closeModal,
  updateTimeslotData,
  calendarDate,
  appointmentsData,
  spotCalendarDate,
  startPoint,
  refetchAuthVet,
  endPoint,
  clientLimit,
  vet
}) => {
  const { user } = useContext(AuthContext);
  const settings = parseUserSettingStartPoint(vet?.user?.userSettings || user?.userSettings);
  const defaultCenter = getLatLng(settings);

  const [errorModal, setErrorModal] = useState([]);
  const [createAreaModal, setCreateAreaModal] = useState(false);
  const [isRecommendationsOpen, setIsRecommendationsOpen] = useState(false);
  const [fieldsAreUpdated, setFieldsAreUpdated] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [customStartPoint, setCustomStartPoint] = useState(startPoint);
  const [customEndPoint, setCustomEndPoint] = useState(endPoint);
  const [customStartPointError, setCustomStartPointError] = useState(false);
  const [customEndPointError, setCustomEndPointError] = useState(false);
  const [isMaxClientsLimitChecked, setIsMaxClientsLimitChecked] = useState(true);
  const [isDefaultPointChecked, setIsDefaultPointChecked] = useState(true);
  const [isUnifyTimeslotChecked, setIsUnifyTimeslotChecked] = useState(true);
  const [maxClientsLimit, setMaxClientsLimit] = useState(0);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const getFormattedRrule = useCallback((date) => {
    return `DTSTART:${moment(date).format(
      'YYYYMMDD'
    )}T120000Z RRULE:FREQ=DAILY;COUNT=1;INTERVAL=1;WKST=MO`;
  }, []);

  const clearStates = () => {
    form.resetFields();
    closeModal();
  };

  const { onSubmit, loading } = useCreateVetTimeSlots(
    clearStates,
    calendarDate,
    refetchAuthVet,
    vet
  );

  const handleCatchFieldUpdate = (e) => {
    if (!fieldsAreUpdated) {
      setFieldsAreUpdated(true);
    }
  };

  useEffect(() => {
    if (updateTimeslotData?.maxAppointments) {
      setMaxClientsLimit(updateTimeslotData?.maxAppointments);
      setIsMaxClientsLimitChecked(false);
    }
    if (updateTimeslotData?.startPoint) {
      setCustomStartPoint(updateTimeslotData?.startPoint);
      setIsDefaultPointChecked(false);
    }
    if (updateTimeslotData?.endPoint) {
      setCustomEndPoint(updateTimeslotData?.endPoint);
      setIsDefaultPointChecked(false);
    }
    if (updateTimeslotData?.isSingle) {
      setIsUnifyTimeslotChecked(false);
    }

    if (updateTimeslotData?.startTime) {
      setStartTime(parseTimeSlotHour(updateTimeslotData?.startTime));
    }
    if (updateTimeslotData?.endTime) {
      setEndTime(parseTimeSlotHour(updateTimeslotData?.endTime));
    }
    form.setFieldValue('workingAreas', updateTimeslotData?.workingAreas || []);
    watchUpdate();
  }, [updateTimeslotData]);

  useEffect(() => {
    if (startPoint && !customStartPoint) setCustomStartPoint(startPoint);
    if (endPoint && !customEndPoint) setCustomEndPoint(endPoint);
  }, [startPoint, endPoint]);

  useEffect(() => {
    if (customEndPointError || customStartPointError) {
      setTimeout(() => {
        setCustomEndPointError(false);
        setCustomStartPointError(false);
      }, 3000);
    }
  }, [customEndPointError, customStartPointError]);

  const addressError = useCallback((address) => {
    return !address?.lat || !address?.lng;
  }, []);

  const initialValues = {
    ...updateTimeslotData,
    date: moment(calendarDate)
  };

  const addressSerializer = (address) => {
    return {
      city: address?.city,
      countryCode: address?.countryCode,
      street: address?.street,
      description: address?.description,
      lat: address?.lat,
      lng: address?.lng,
      zipCode: address?.zipCode,
      houseNumber: parseInt(customStartPoint?.houseNumber)
    };
  };

  const [, _watchUpdate] = useState();
  const watchUpdate = () => _watchUpdate(new Date());
  const workingAreas = form.getFieldValue('workingAreas') || [];
  return (
    <>
      <Modal
        force
        className={css.modal}
        isOpen={true}
        onClose={clearStates}
        customFooter={() => null}
        title={t('add.timeslot')}>
        <S.Recommendations>
          <S.Heading onClick={() => setIsRecommendationsOpen((prev) => !prev)}>
            {t('timeslot_recommendations.recommendations')}
            <S.DropdownImg src={arrow} isRecommendationsOpen={isRecommendationsOpen} />
          </S.Heading>

          <S.UL isRecommendationsOpen={isRecommendationsOpen}>
            <S.SingleReccomendation>
              {t('timeslot_recommendations.make_short')}
            </S.SingleReccomendation>
            <S.SingleReccomendation>
              {t('timeslot_recommendations.morning_or_evening')}
            </S.SingleReccomendation>
          </S.UL>
        </S.Recommendations>

        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          disabled={loading}
          initialValues={initialValues}
          className="add-timeslot-form"
          onFinish={(values) => {
            if (!updateTimeslotData?.uid && !fieldsAreUpdated) {
              return;
            }

            if (workingAreas?.length === 0) {
              setErrorModal([t('create_timeslot.validations.working_areas')]);
              return;
            }

            const formValues = {
              ...values,
              workingAreas
            };

            const addressPoints = {};

            if (initialValues.uid) {
              formValues.uid = initialValues.uid;
            }
            formValues.rule = getFormattedRrule(formValues.date);
            formValues.isSingle = !isUnifyTimeslotChecked;

            formValues.startTime = startTime || moment(formValues.startTime).format('hh:mm a');
            formValues.endTime = endTime || moment(formValues.endTime).format('hh:mm a');
            if (!isMaxClientsLimitChecked) formValues.maxAppointments = maxClientsLimit;
            if (!isDefaultPointChecked) {
              if (addressError(customStartPoint) || addressError(customEndPoint)) {
                if (addressError(customStartPoint)) setCustomStartPointError(true);
                if (addressError(customEndPoint)) setCustomEndPointError(true);
                return;
              }
              addressPoints.startPoint = addressSerializer(customStartPoint);
              addressPoints.endPoint = addressSerializer(customEndPoint);
            }

            for (const value in values) {
              if (value.includes('addressDescription')) {
                delete formValues[value];
              }
            }

            spotCalendarDate(formValues?.date);
            onSubmit(formValues, addressPoints);
          }}>
          <Title level={5} className="working-area-title">
            {t('name')}
          </Title>
          <Row>
            <Col span={24} className={css.inputContainer}>
              <Input
                onChange={handleCatchFieldUpdate}
                required
                name="name"
                placeholder={t('name.the.timeslot')}
                whitespace
              />
            </Col>
          </Row>

          <Title level={5} className="working-area-title">
            {t('timeslot_modal.working_area_lbl')}
          </Title>
          <Row>
            <Col span={24}>
              <WorkingAreasSelectMultiple
                placeholder={t('timeslot_modal.select_wa_placeholder')}
                onSelect={(areas) => {
                  form.setFieldValue('workingAreas', areas);
                  watchUpdate();
                }}
                values={workingAreas}
              />
              {!createAreaModal && (
                <ButtonFSD
                  className={css.buttonCreate}
                  onClick={() => setCreateAreaModal(true)}
                  icon={<PlusOutlined />}>
                  {t('timeslot_modal.add_working_area_btn')}
                </ButtonFSD>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <WorkingAreasMapView
                data={workingAreas}
                loading={false}
                handleClickArea={() => {}}
                disableControls
                mapClass={css.mapClass}
                containerClass={css.containerClass}
              />
            </Col>
          </Row>

          <Title level={5} className="working-area-title">
            {t('choose.date')}
          </Title>
          <Row>
            <Col span={24}>
              <DatePicker
                required
                name="date"
                date={calendarDate}
                onChange={handleCatchFieldUpdate}
              />
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Title level={5} className="working-area-title">
                {t('start.time')}
              </Title>
              <Form.Item
                name="startTime"
                rules={[{ required: true, message: t('field.is.required') }]}
                trigger="onSelect">
                <TimePicker
                  onChange={handleCatchFieldUpdate}
                  name="startTime"
                  form={form}
                  initialValues={initialValues}
                  placeholder={'00:00'}
                  value={startTime}
                  onSelect={(time) => {
                    setStartTime(time);
                    form.setFieldsValue({
                      startTime: moment(time, 'hh:mm a')
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Title level={5} className="working-area-title">
                {t('end.time')}
              </Title>
              <Form.Item
                name="endTime"
                rules={[{ required: true, message: t('field.is.required') }]}
                trigger="onSelect">
                <TimePicker
                  onChange={handleCatchFieldUpdate}
                  name="endTime"
                  form={form}
                  initialValues={initialValues}
                  placeholder={'00:00'}
                  value={endTime}
                  onSelect={(time) => {
                    setEndTime(time);
                    form.setFieldsValue({
                      endTime: moment(time, 'hh:mm a')
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {!updateTimeslotData || updateTimeslotData ? (
            <>
              <Row gutter={8} style={{ alignItems: 'center', marginBottom: 8 }}>
                <Checkbox
                  checked={isMaxClientsLimitChecked}
                  onClick={() => setIsMaxClientsLimitChecked((prev) => !prev)}>
                  <span className="checkbox-text">{t('timeslot_modal.clients_limit')}</span>
                </Checkbox>
                {!isMaxClientsLimitChecked && (
                  <InputNumber
                    controls={false}
                    addonAfter={<UserIcon />}
                    value={isMaxClientsLimitChecked ? clientLimit : maxClientsLimit}
                    onChange={(e) => setMaxClientsLimit(e)}
                    disabled={isMaxClientsLimitChecked}
                    className="client-limit-input"
                  />
                )}
              </Row>
              {!updateTimeslotData?.uid && (
                <>
                  <Row gutter={8} style={{ alignItems: 'center', marginBottom: 8 }}>
                    <Checkbox
                      checked={isDefaultPointChecked}
                      onClick={() => setIsDefaultPointChecked((prev) => !prev)}>
                      <span className="checkbox-text">{t('timeslot_modal.default_points')}</span>
                    </Checkbox>
                  </Row>
                  <Row gutter={8} style={{ alignItems: 'center', marginBottom: 8 }}>
                    <Col span={12}>
                      <span className="checkbox-text">{t('start.point')}</span>
                      <AddressAutocomplete
                        disabled={isDefaultPointChecked}
                        className="address-autocomplete-input"
                        address={customStartPoint?.description || customStartPoint}
                        onChange={(e) => {
                          setCustomStartPoint(e);
                          handleCatchFieldUpdate();
                        }}
                        onSelect={setCustomStartPoint}
                        customErrorMessage={customStartPointError}
                      />
                    </Col>
                    <Col span={12}>
                      <span className="checkbox-text">{t('end.point')}</span>
                      <AddressAutocomplete
                        disabled={isDefaultPointChecked}
                        className="address-autocomplete-input"
                        address={customEndPoint?.description || customEndPoint}
                        onChange={(e) => {
                          setCustomEndPoint(e);
                          handleCatchFieldUpdate();
                        }}
                        onSelect={setCustomEndPoint}
                        customErrorMessage={customEndPointError}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : null}

          <Row gutter={8} style={{ alignItems: 'center', marginBottom: 8 }}>
            <Checkbox
              checked={isUnifyTimeslotChecked}
              onClick={() => {
                setIsUnifyTimeslotChecked((prev) => !prev);
                handleCatchFieldUpdate();
              }}>
              <span className="checkbox-text">{t('timeslot_modal.is_single_timeslot')}</span>
            </Checkbox>
          </Row>

          <Row gutter={12} justify="end" className="ts-footer">
            <Col>
              <Button className="cancel-button" style={{ width: 'auto' }} onClick={clearStates}>
                {t('cancel')}{' '}
              </Button>
            </Col>
            <Col>
              {updateTimeslotData?.uid && appointmentsData && appointmentsData.length ? (
                <Popconfirm
                  title={t('edit.timeslot.confirm')}
                  onConfirm={() => !loading && form.submit()}
                  onCancel={() => closeModal()}
                  okText="Yes"
                  cancelText="No">
                  <div className="submit-btn-wrapper">
                    <a href="#">{t('ok')}</a>
                  </div>
                </Popconfirm>
              ) : (
                <SubmitButton disabled={loading} onClick={form.submit} value={t('ok')} />
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
      {createAreaModal && (
        <CreateWorkingArea
          defaultCenter={defaultCenter}
          pushIsSavedSelectModal
          createAreaModal={createAreaModal}
          onClose={(area) => {
            setCreateAreaModal(false);
            const areas = [...workingAreas.filter((a) => a.uid !== area?.uid)];
            if (area?.uid) {
              areas.push(area);
            }
            form.setFieldValue('workingAreas', areas);
            watchUpdate();
          }}
        />
      )}
      <Modal
        hideClose
        icon={<ExclamationCircleOutlined />}
        title={t('create_timeslot.validations.title')}
        isOpen={errorModal.length}
        footerProps={{
          displayCancel: false
        }}
        onConfirm={() => {
          setErrorModal([]);
        }}
        onClose={() => {
          setErrorModal([]);
        }}>
        <div className={css.validationModal}>
          {errorModal.map((err) => (
            <span key={err}>{err}</span>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default memo(AddTimeslotModal);
