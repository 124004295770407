import React from 'react';
import cls from 'classnames';
import { Button, ButtonColors, cssText } from '_fsd/shared';
import css from './ClinicAdminAddAreaTutorial.module.css';
import { useTranslation } from 'react-i18next';

export const ClinicAdminAddAreaTutorial = ({ addArea }) => {
  const { t } = useTranslation();
  return (
    <div className={css.container}>
      <span className={cssText.s24w5h28}>
        {t('settings_page.service_area.tutorial.page_title')}
      </span>
      <div className={css.row}>
        <div className={css.column}>
          <span className={cls(cssText.s16w4h24, css.description)}>
            {t('settings_page.service_area.tutorial.description')}
          </span>
          <Button
            onClick={addArea}
            className={css.addButton}
            type="primary"
            size="large"
            colorScheme={ButtonColors.BLUE}>
            {t('settings_page.service_area.tutorial.add_button')}
          </Button>
        </div>
        <div className={css.column}>
          <iframe
            width="100%"
            height="400px"
            src="https://www.youtube.com/embed/TYcW56hpTTY?si=h5H9iFkxSubo-5dS"
            title="Kumba - Pre-set polygons"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen></iframe>
        </div>
      </div>
    </div>
  );
};
