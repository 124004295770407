import React from 'react';
import { Skeleton } from 'antd';
import css from './SkeletonTile.module.css';

export const SkeletonTile = (props) => (
  <div className={css.container}>
    <Skeleton.Button active size="large" block className={css.node} />
    <Skeleton.Button active block />
    <Skeleton.Button active size="small" block />
  </div>
);
