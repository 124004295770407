import { gql } from 'graphql.macro';
import { useQuery } from '@apollo/client';

export const useZipCodes = (options = {}) => {
  const { data, loading } = useQuery(query, options);
  return { data: data?.getZipCodes, loading };
};

const query = gql`
  query getZipCodes($zip: String!) {
    getZipCodes(zip: $zip) {
      uid
      zip
    }
  }
`;
