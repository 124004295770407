import { useState } from 'react';

export const useFilters = ({
  vetId,
  displayTSNotInAreaDef = false,
  displayFullTSDef = false,
  displayVetsDef = false,
  displayLockedDef = true,
  selectedVetsDef = []
}) => {
  const [selectedVets, setSelectedVets] = useState(vetId ? [vetId] : selectedVetsDef);
  const [displayVets, setDisplayVets] = useState(displayVetsDef);
  const [displayLocked, setDisplayLocked] = useState(displayLockedDef);
  const [displayFullTS, setFullTS] = useState(displayFullTSDef);
  const [displayTSNotInArea, setDisplayTSNotInArea] = useState(displayTSNotInAreaDef);

  return {
    selectedVets,
    setSelectedVets,
    displayVets,
    setDisplayVets,
    displayFullTS,
    setFullTS,
    displayTSNotInArea,
    setDisplayTSNotInArea,
    displayLocked,
    setDisplayLocked
  };
};
