import React, { useEffect, useState, memo } from 'react';
import css from './WorkingAreasScroll.module.css';
import { SkeletonTile, WorkingAreaTile } from '_fsd/entities';
import { useOnScreen } from '_fsd/shared';

const WorkingAreasScrollComponent = ({ data, loading, loadMore, onRemove, onEdit }) => {
  const hasMore = data?.data?.length < data?.total;

  const { measureRef, isIntersecting, observer } = useOnScreen({
    threshold: 0
  });

  const [triggerLoader, setTriggerLoader] = useState(false);
  useEffect(() => {
    if (isIntersecting && hasMore && !loading && !triggerLoader) {
      setTriggerLoader(true);
      loadMore();
      observer.disconnect();
    }
  }, [isIntersecting, hasMore, loadMore, triggerLoader]);

  useEffect(() => {
    if (!loading) {
      setTriggerLoader(false);
    }
  }, [loading]);

  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        {data?.data?.length === 0 && loading ? <SkeletonTile /> : null}
        {data?.data?.map((wa, i, arr) => (
          <React.Fragment key={wa.uid}>
            <WorkingAreaTile data={wa} onEdit={onEdit} onRemove={onRemove} />
            {i === arr.length - 1 && loading ? <SkeletonTile /> : null}
            {i === arr.length - 1 ? <div className={css.marker} ref={measureRef} /> : null}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export const WorkingAreasScroll = memo(WorkingAreasScrollComponent);
