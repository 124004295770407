import React, { useRef, useState } from 'react';
import { Button, ButtonColors, cssText } from '_fsd/shared';
import { Input } from 'antd';
import css from './WorkingAreasFilters.module.css';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { PlusOutlined } from '@ant-design/icons';

export const WorkingAreasFilters = ({ loading, setFilters, onCreateArea }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const debouncedInput = useRef(debounce(setFilters, 300));

  return (
    <div className={css.container}>
      <div className={css.row}>
        <span className={cssText.s16h24w5}>{t('settings_page.service_area.areas.title')}</span>
        <div className={css.controls}>
          <Input.Search
            className={css.input}
            loading={loading && !!value}
            size="medium"
            onChange={(e) => {
              debouncedInput.current({ record: { name: e.currentTarget.value } });
              setValue(e.currentTarget.value);
            }}
            placeholder={t('settings_page.service_area.areas.search_placeholder')}
          />
          <Button
            icon={<PlusOutlined />}
            size="large"
            type="default"
            colorScheme={ButtonColors.BLUE}
            onClick={onCreateArea}>
            {t('settings_page.service_area.areas.create_button')}
          </Button>
        </div>
      </div>
      <div className={css.hr} />
    </div>
  );
};
