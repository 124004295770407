import React from 'react';
import { cssText } from '_fsd/shared';
import css from './WorkingAreasSelectType.module.css';
import { useTranslation } from 'react-i18next';
import { AREA_TYPE } from '_fsd/entities';
import cls from 'classnames';
import { ReactComponent as IconPolygon } from 'assets/icons/icon-polygon.svg';
import { ReactComponent as IconZip } from 'assets/icons/icon-zip.svg';

export const WorkingAreasSelectType = ({ value, onSelect }) => {
  const { t } = useTranslation();

  return (
    <div className={css.type}>
      <span className={cssText.s16w4h24}>
        {t('settings_page.service_area.create_area_modal.label_type')}
      </span>
      <div
        onClick={() => onSelect(AREA_TYPE.POLYGON)}
        className={cls(css.typeItem, {
          [css.selected]: value === AREA_TYPE.POLYGON
        })}>
        <div className={css.icon}>
          <IconPolygon />
        </div>
        <div className={css.type_column}>
          <span className={cssText.s16w5l24}>
            {t('settings_page.service_area.create_area_modal.title_type_polygon')}
          </span>
          <span className={cssText.s14w4l20fI}>
            {t('settings_page.service_area.create_area_modal.desc_polygon')}
          </span>
        </div>
      </div>
      <div
        onClick={() => onSelect(AREA_TYPE.ZIP)}
        className={cls(css.typeItem, {
          [css.selected]: value === AREA_TYPE.ZIP
        })}>
        <div className={css.icon}>
          <IconZip />
        </div>
        <div className={css.type_column}>
          <span className={cssText.s16w5l24}>
            {t('settings_page.service_area.create_area_modal.title_type_zip')}{' '}
            <span className={cssText.s16w4h24}>
              {t('settings_page.service_area.create_area_modal.title_type_zip_note')}
            </span>
          </span>
          <span className={cssText.s14w4l20fI}>
            {t('settings_page.service_area.create_area_modal.desc_zip')}
          </span>
        </div>
      </div>
    </div>
  );
};
