import React from 'react';
import style from './Popover.module.css';
import { Popover as PopoverAntd } from 'antd';

const Popover = ({ children, ...props }) => (
  <PopoverAntd showArrow={false} overlayClassName={style.popoverContainer} {...props}>
    {children}
  </PopoverAntd>
);

export default Popover;
