import React from 'react';
import styled, { css } from 'styled-components';

export const Header = styled.header`
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 6.4rem;
  padding: 1rem 3.2rem 1rem 1.6rem;
  box-shadow: 0 1.7rem 4.5rem -3.1rem rgba(5, 40, 72, 0.25);
  background-color: ${(props) => props.theme.colors.white};
  ${({ theme }) => theme.mobile`
    position: absolute;
    top: 0;
    z-index: 3;
    align-items: center;
    padding: 1.2rem 2rem;
    height: 5.6rem;
  `}
`;

export const ActionsNavigation = styled.nav`
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const ProfileWrapper = styled.div`
  ${({ theme }) => theme.mobile`
  display:none;
`}
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ProfileImage = styled.div`
  margin-left: 1.9rem;
  width: 4.4rem;
  height: 4.4rem;
`;

export const Name = styled.h3`
  max-width: 10rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  height: 2rem;
  margin: 1.2rem 0.8rem 1.2rem 1.1rem;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.black};
`;

export const Vector = styled.img`
  width: 1.1rem;
  height: 0.68rem;
  opacity: 0.64;
  transition: 0.3s ease-in-out;
  ${({ menuOpen }) =>
    menuOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ProfileMenu = styled.div`
  position: fixed;
  width: 14.6rem;
  right: 0;
  margin-top: 1.5rem;
  margin-right: 2.51rem;
  padding: 0.8rem;
  border-radius: 0.6rem;
  box-shadow: 0 17px 45px 0 rgba(5, 40, 72, 0.08);
  background-color: ${(props) => props.theme.colors.white};
  z-index: 999;
  border: 1px solid var(--color-gray-border);
  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 0.95rem solid transparent;
    border-right: 0.95rem solid transparent;
    border-bottom: 1.1rem solid white;
    right: 0.25rem;
    top: -0.6rem;
    z-index: -1;
  }
`;

export const ProfileSection = styled.button`
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  padding-left: 1.3rem;
  align-items: center;
  height: 4.1rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0.8rem;
  cursor: pointer;
  :hover {
    background-color: var(--color-gray-hover);
  }
`;

export const ProfileSectionTitle = styled.p`
  height: 1.7rem;
  margin-left: 1.3rem;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--color-text-blue);
`;

export const MenuIcon = styled.img`
  display: none;
  ${({ theme }) => theme.mobile`
    display: block;
    cursor:pointer;
  `}
`;

export const LogoWrapper2 = styled.div`
  height: 16px;
  width: 17rem;
  ${({ theme }) => theme.mobile`
      display: none;
  `}
`;

export const LogoWrapper = styled.div`
  height: 16px;
  width: 16px;
  ${({ theme }) => theme.mobile`
      display: none;
  `}
`;

export const Logo = styled.img`
  ${({ theme }) => theme.mobile`
      display: none;
  `}
`;

export const SmallLogo = styled.img`
  display: none;
  ${({ theme }) => theme.mobile`
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
  `}
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .ant-breadcrumb {
    width: 100%;
  }
  .ant-breadcrumb-link {
    font-family: Inter, serif;
    font-weight: 400;
    font-size: 1.4rem;
  }
  ${({ theme }) => theme.mobile`
      display: none;
  `}
`;

export const ListItem = ({ img, text, onClick }) => {
  const Logo = img;
  return (
    <ProfileSection onClick={onClick}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <ProfileSectionTitle>{text}</ProfileSectionTitle>
    </ProfileSection>
  );
};
