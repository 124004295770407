import styled from 'styled-components';

export const ButtonWithIcon = styled.div`
  width: ${({ size }) => (size === 'big' ? '14.5rem' : size === 'medium' ? '12.4rem' : '10rem')};
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.9rem;
  font-size: 1.4rem;
  background: ${({ active, theme }) => (active ? theme.colors.darkGreen : theme.colors.white)};
  color: ${({ active, theme }) => (active ? theme.colors.white : 'rgba(0,0,0,.85)')};
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.043);
  border-radius: 0.2rem;
  border: 1px solid transparent;
  border-color: ${({ active, theme }) => (active ? 'transparent' : theme.colors.lightGray)};
  cursor: pointer;
  font-family: Inter;
  font-weight: 400;
`;
