import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GoogleMap, Polygon } from '@react-google-maps/api';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import css from './WorkingAreasMapView.module.css';
import { TagEditable, attachSearchToGMap, generateColors } from '_fsd/shared';
import { AREA_TYPE } from '_fsd/entities/working-area';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import { getZipArea } from '../../../entities/zip';
import mapStyle from '_fsd/app/map_styles.json';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';

const { google } = window;

const DEFAULT_CENTER = {
  lat: 38.1843034,
  lng: -120.7975979
};

const DEFAULT_AREA_NAME = 'Area';

export const fitBounds = (map, areas, zipPolygons) => {
  const bounds = new google.maps.LatLngBounds();
  areas?.forEach((area) =>
    area.polygons.forEach((p) => {
      p.area.forEach((a) => {
        bounds.extend(new window.google.maps.LatLng(a.lat, a.lng));
      });
    })
  );
  zipPolygons?.forEach((zipCode) =>
    zipCode.polygons.forEach((p) => {
      p.forEach((a) => {
        bounds.extend(new window.google.maps.LatLng(a.lat, a.lng));
      });
    })
  );
  if (zipPolygons?.length || areas?.length) {
    map.panTo(bounds.getCenter());
    map.fitBounds(bounds, 50);
  }
};

export const WorkingAreasMapView = ({
  data,
  loading,
  handleClickArea,
  disableControls,
  mapClass,
  containerClass,
  defaultCenter = DEFAULT_CENTER
}) => {
  const [mapRef, setMapRef] = useState(null);
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const [zipPolygons, setZipPolygons] = useState([]);

  useEffect(() => {
    if (mapRef && data && zipPolygons && !loading) {
      fitBounds(mapRef, data, zipPolygons);
    }
  }, [data, zipPolygons, loading, mapRef]);

  useEffect(() => {
    if (data?.length) {
      const zips = uniq(
        flatMap(
          data
            .map((wa, i) => {
              if (wa.type === AREA_TYPE.ZIP) {
                return {
                  ...wa,
                  zipCodes: wa.zipCodes.map((z) => ({
                    index: i,
                    ...z
                  }))
                };
              }
              return wa;
            })
            .filter((z) => z.type === AREA_TYPE.ZIP),
          (a) => a.zipCodes.map((z) => ({ zip: z.zip, index: z.index }))
        )
      );
      if (zips.length) {
        const zipsCodes = zips.map((z) => z.zip);
        getZipArea(zipsCodes).then((res) => {
          const zipPolygonsObjects = res.map((r) => ({
            ...r,
            index: zips.find((z) => z.zip === r.zip)?.index
          }));
          setZipPolygons(zipPolygonsObjects);
        });
      }
    } else {
      setZipPolygons([]);
    }
  }, [data]);

  const colors = useMemo(() => {
    if (data?.length > 0) {
      return generateColors(data.length);
    }
    return [];
  }, [data]);

  return (
    <div className={cls(css.container, containerClass)}>
      {!disableControls && (
        <input
          ref={inputRef}
          className={css.input}
          placeholder={t('settings_page.service_area.create_area_modal.placeholder_map_search')}
        />
      )}
      <GoogleMap
        colors
        mapContainerClassName={cls(css.map, mapClass)}
        center={defaultCenter}
        zoom={7}
        onLoad={(map) => {
          setMapRef(map);
          fitBounds(map, data);
          attachSearchToGMap(inputRef, map, disableControls);
        }}
        version="weekly"
        options={{
          fullscreenControl: true,
          streetViewControl: false,
          gestureHandling: 'cooperative',
          styles: mapStyle
        }}>
        <LoaderWrapper isLoading={!mapRef}>
          {data?.map((area, index) => {
            if (area.type === AREA_TYPE.POLYGON) {
              return area.polygons?.map((p, i) => (
                <Polygon
                  options={{
                    fillColor: colors[index],
                    fillOpacity: 0.12,
                    strokeColor: colors[index],
                    strokeOpacity: 1,
                    strokeWeight: 2
                  }}
                  key={area.uid + p.uid}
                  path={p.area}
                  // onClick={() => handleClickArea(area)}
                />
              ));
            }
            return null;
          })}
          {zipPolygons?.map((zip) => {
            return zip.polygons.map((p, index) => (
              <Polygon
                options={{
                  fillColor: colors[zip.index],
                  fillOpacity: 0.12,
                  strokeColor: colors[zip.index],
                  strokeOpacity: 1,
                  strokeWeight: 2
                }}
                key={index}
                path={p}
              />
            ));
          })}
        </LoaderWrapper>
      </GoogleMap>
      {!disableControls && (
        <div className={css.areas}>
          {data?.map((area, i) => (
            <TagEditable color={colors[i]} key={area.uid} onClick={() => handleClickArea(area)}>
              {area.name || DEFAULT_AREA_NAME}
            </TagEditable>
          ))}
        </div>
      )}
    </div>
  );
};
