import React, { memo, useState, useEffect } from 'react';
import { Row, Col, Button, Table, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { EyeFilled } from '@ant-design/icons';
import { Modal } from '_fsd/shared';

import Input from 'components/vet-components/vet-web-inputs/input/Input';
import UploadImage from 'components/vet-components/vet-web-inputs/upload/UploadImage';
import VetPhoneInput from 'components/vet-components/phone-input/VetPhoneInput';
import AddressAutocomplete from 'components/vet-components/address-autocomplete/AddressAutocomplete';
import TextArea from 'components/vet-components/vet-web-inputs/text-area/TextArea';
import SubmitButton from 'components/vet-components/vet-web-inputs/submit-button/SubmitBtn';
import InputNumber from 'components/vet-components/vet-web-inputs/input-number/InputNumber';

import useEditClientModalColumns from './useEditClientModalColumns';
import useEditClientModalData from './useClientModal';
import useRemovePet from '../pet-modal/useRemovePet';
import useUpdateUser from '../edit-pet-owner/useUpdateUser';

import theme from 'styles/theme';
import './styles.css';

import Cloud from 'assets/icons/cloud.svg';
import { ReactComponent as Plus } from 'assets/icons/plus-green.svg';
import ButtonWithIcon from '../../../components/button-with-icon/ButtonWithIcon';
import { PropertyType } from '../../../utils/enums';
import { ReactComponent as HomeIcon } from '../../../assets/icons/home-icon.svg';
import { ReactComponent as HomeDarkIcon } from '../../../assets/icons/home-dark.svg';
import { ReactComponent as BuildingIcon } from '../../../assets/icons/building-white.svg';
import { ReactComponent as BuildingDarkIcon } from '../../../assets/icons/building-dark.svg';

const EditClientModal = ({
  closeModal,
  customerData,
  formIsReseted,
  setFormIsReseted,
  setEditPet
}) => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.tabletL}px)`
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const { updateUser } = useUpdateUser();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { handleRemovePet } = useRemovePet();
  const [address, setAddress] = useState('');
  const columnStyles = { xs: 24, lg: 12 };
  const rowStyles = { gutter: 12 };

  const [clientImageUrl, setClientImageUrl] = useState('');

  const handleSelectAddress = (area) => {
    form.setFieldsValue({
      address: {
        city: area.city,
        street: area.street,
        countryCode: area.countryCode,
        description: area.description,
        zipCode: area.zipCode,
        lat: area.lat,
        lng: area.lng
      },
      addressDescription: area.description
    });
  };

  const { petColumns } = useEditClientModalColumns(handleRemovePet, setEditPet);
  const { formInitialValues, getPetsByUserData, propertyType, setPropertyType } =
    useEditClientModalData(customerData);
  useEffect(() => {
    if (formInitialValues.address?.description) {
      setAddress(formInitialValues.address?.description);
    }
  }, [formInitialValues]);

  useEffect(() => {
    if (!formIsReseted && formInitialValues?.uid) {
      form.resetFields();
      setFormIsReseted && setFormIsReseted(true);
    }
  }, [formInitialValues, formIsReseted]);

  return (
    <>
      <Modal
        className="edit-client-modal-wrapper"
        isOpen={customerData}
        title={t('client.details')}
        onClose={closeModal}
        customFooter={() => null}>
        <Form
          form={form}
          layout="vertical"
          className="client-modal-info"
          initialValues={formInitialValues}
          requiredMark={false}
          onFinish={(values) => {
            if (values.phoneNumber || values.phoneNumber.length) {
              setSubmitting(true);
              const formValues = {
                ...values,
                address: {
                  ...values.address,
                  propertyType
                }
              };
              formValues.avatarUrl = clientImageUrl;
              delete formValues.addressDescription;
              delete formValues.phoneNumberDisplay;
              updateUser(formValues, customerData.userId)
                .then(() => closeModal())
                .finally(() => setSubmitting(false));
            }
          }}>
          {/* <h2 className='client-modal-title'>{t('client.details')}</h2>*/}
          <h3 className="client-modal-subtitle">
            <EyeFilled className="subtitle-icon" />
            {t('all.fields.visible.to.clients')}
          </h3>

          <Row {...rowStyles}>
            <Col xs={24} lg={5}>
              <UploadImage
                name="avatarUrl"
                form={form}
                formInitialValues={formInitialValues}
                imageUrl={clientImageUrl}
                setImageUrl={setClientImageUrl}
              />
            </Col>
            <Col xs={24} lg={19}>
              <Row gutter={12}>
                <Col {...columnStyles}>
                  <Input
                    label={t('first.name.required')}
                    placeholder={t('first.name')}
                    name="firstName"
                    required
                    whitespace={true}
                  />
                </Col>
                <Col {...columnStyles}>
                  <Input
                    label={t('lastName.required')}
                    name="lastName"
                    placeholder={t('lastName')}
                    required
                    whitespace={true}
                  />
                </Col>
              </Row>
              <Row {...rowStyles}>
                <Col {...columnStyles}>
                  <Input
                    label={t('email.required')}
                    placeholder={t('email')}
                    name="email"
                    required
                    type="email"
                  />
                </Col>

                <Col {...columnStyles}>
                  <VetPhoneInput form={form} name="phoneNumberDisplay" />
                </Col>
              </Row>

              <Row {...rowStyles}>
                <Col span={24}>
                  <AddressAutocomplete
                    label={t('vet_profile.default.routes')}
                    placeholder={t('vet_profile.default.routes')}
                    address={address}
                    onChange={setAddress}
                    onSelect={handleSelectAddress}
                    name="addressDescription"
                    required
                  />
                </Col>
              </Row>

              <Row style={{ marginBottom: 15 }}>
                <Col>
                  <ButtonWithIcon
                    size="big"
                    text={t('private.house')}
                    active={propertyType === PropertyType.PrivateHome}
                    onClick={() => setPropertyType(PropertyType.PrivateHome)}
                    icon={
                      propertyType === PropertyType.PrivateHome ? <HomeIcon /> : <HomeDarkIcon />
                    }
                  />
                </Col>
                <Col style={{ marginLeft: 20 }}>
                  <ButtonWithIcon
                    size="medium"
                    text={t('apartment')}
                    active={propertyType === PropertyType.Apartment}
                    onClick={() => {
                      setPropertyType(PropertyType.Apartment);
                    }}
                    icon={
                      propertyType === PropertyType.Apartment ? (
                        <BuildingIcon />
                      ) : (
                        <BuildingDarkIcon />
                      )
                    }
                  />
                </Col>
              </Row>
              {propertyType === PropertyType.Apartment && (
                <Row {...rowStyles}>
                  <Col {...columnStyles}>
                    <InputNumber
                      label={t('floor')}
                      placeholder={t('floor')}
                      name="floor"
                      whitespace={true}
                      required={propertyType === PropertyType.Apartment}
                    />
                  </Col>

                  <Col {...columnStyles}>
                    <Input
                      label={t('apartment')}
                      placeholder={t('apartment')}
                      name="apartment"
                      whitespace={true}
                      required={propertyType === PropertyType.Apartment}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col span={24}>
                  <TextArea name="comment" label={t('comment')} placeholder={t('comment')} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <span>{t('pets')}</span>
              {getPetsByUserData.length > 0 ? (
                <Table
                  columns={petColumns}
                  dataSource={getPetsByUserData}
                  size="small"
                  pagination={{ pageSize: 4 }}
                />
              ) : (
                <div className="no-pets-wrapper">
                  <div className="no-pets-content">
                    <img src={Cloud} />
                    <h2 className="no-pets-text">{t('client.has.no.pets')}</h2>
                    <div className="add-new-pet-button" onClick={() => setEditPet({})}>
                      <Plus />
                      {t('add.new.pet')}
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>

          <Row gutter={12} justify="end">
            <Col>
              <Button disabled={isSubmitting} className="cancel-button" onClick={closeModal}>
                {t('cancel')}{' '}
              </Button>
            </Col>
            <Col>
              <SubmitButton disabled={isSubmitting} onClick={form.submit} value={t('save')} />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(EditClientModal);
