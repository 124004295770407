import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import { cssText, cssColor } from '../../../_fsd/shared';
import css from './SignUp.module.css';
import { ReactComponent as SvgArrowLef } from '../../../assets/icons/arrow-left.svg';
import { Link as LinkDom } from 'react-router-dom';

import { Wrapper, Container, Content, Link, BottomSection, Question } from './components';

import SignUpForm from 'components/sign-up-form/SignUpForm';

import { SIGN_IN, SIGN_IN_VET, SIGN_UP } from 'constants/client';
import { PageTitle, PageSubTitle } from 'components/common/texts/components';

const SignUp = (props) => {
  const location = useLocation();
  const { t } = useTranslation();

  const isSignUp = location.pathname.toLocaleLowerCase().includes(SIGN_UP);
  const isVet = location.pathname.toLocaleLowerCase().includes(SIGN_IN_VET);

  const redirectUrl = props?.location?.query?.redirectUrl;
  return (
    <Wrapper>
      <LinkDom to={'..'} className={cls(cssText.s14h24w5, cssColor.title85, css.back)}>
        <SvgArrowLef /> Back
      </LinkDom>
      <Container>
        <PageTitle>{isVet ? t('Caregivers Sign In') : t('Pet Owner Sign In')}</PageTitle>
        <PageSubTitle>
          {t('Enter your phone number to receive a verification code for account access')}
        </PageSubTitle>
        <Content>
          <SignUpForm redirectUrl={redirectUrl} isVet={isVet} />
          <BottomSection>
            {isVet ? (
              <>
                <Question>{t(isSignUp ? 'already.have.account?' : 'dont.have.account?')}</Question>
                <Link to={isSignUp ? SIGN_IN : SIGN_UP}>
                  {t(isSignUp ? 'sign.in' : 'sign.up.for.free')}
                </Link>
              </>
            ) : (
              <div className={css.petOwnerDescription}>
                <span className={cls(cssText.s16h24w5i, cssColor.cB0B7C3)}>
                  To sign up, please contact your vet.
                </span>
              </div>
            )}
          </BottomSection>
        </Content>
      </Container>
      <div />
    </Wrapper>
  );
};
export default SignUp;
