import React from 'react';
import css from './IntegrationCredentialsEditor.module.css';
import { Input, message } from 'antd';
import { useFormik } from 'formik';
import { cssText, Modal } from '_fsd/shared';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { IntegrationIcon, useUpdateIntegration } from '_fsd/entities/integration';

const getSchema = (templateCredentials = []) => {
  const schema = {};
  templateCredentials.forEach((field) => {
    schema[field.name] = field.required ? Yup.string().required() : Yup.string().notRequired();
  });

  return Yup.object(schema);
};

export const IntegrationCredentialsEditorContent = ({ form, template }) => {
  const { t } = useTranslation();
  return (
    <div className={css.container}>
      <span className={css.description}>{t('integration.credentials_editor.description')}</span>
      {template?.credentials?.map((field) => (
        <div key={field.name} className={css.field}>
          <span className={cssText.s12h20w4}>{field.label}</span>
          <Input
            status={!!form.errors[field.name] && !form.isValid ? 'error' : undefined}
            name={field.name}
            type={field.type}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={field.placeholder}
          />
          {!!form.errors[field.name] && !form.isValid && (
            <span className={cssText.s12w4l20_error}>{field.error}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export const IntegrationCredentialsEditor = ({ closeModal, data }) => {
  const [updateIntegration] = useUpdateIntegration();
  const { t } = useTranslation();
  const {
    uid,
    pim: { template, name }
  } = data;

  const form = useFormik({
    initialValues: {},
    validationSchema: getSchema(template?.credentials),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true);
      updateIntegration({
        variables: {
          record: {
            uid,
            settings: {
              credentials: values
            }
          }
        }
      })
        .then(() => {
          message.success('Credentials have been updated!');
          closeModal();
        })
        .catch((err) => message.error(err.message))
        .finally(() => {
          form.setSubmitting(false);
        });
    }
  });
  return (
    <Modal
      isOpen
      onClose={() => closeModal()}
      force
      onConfirm={() => {
        form.submitForm();
      }}
      icon={<IntegrationIcon bgColor={'var(--color-blue)'} />}
      title={
        <span>
          {t('integration.credentials_editor.title')}
          <b>{name}</b>
        </span>
      }>
      <IntegrationCredentialsEditorContent form={form} template={template} />
    </Modal>
  );
};
