import React, { useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from 'common_repo/assets/icons/logo-blue-beta.svg';
import SmallLogo from 'common_repo/assets/icons/small-logo-blue-beta.svg';
import { getInitialLetters } from 'utils/helpers';
import useOutsideClickDetector from 'hooks/useOutsideClickDetector';
import { ReactComponent as LogOutImage } from 'assets/icons/logout.svg';
import { ReactComponent as HelpCenterImage } from 'assets/icons/help-center.svg';
import arrow from 'assets/icons/arrow-down.svg';
import menu from 'assets/icons/burgermenu.svg';
import DynamicAvatar from 'components/dynamic-avatar/DynamicAvatar';
import BreadCrumb from 'components/breadcrumb/BreadCrumb';
import * as S from './components';
import AuthContext from '../../contexts/AuthContext';
import { Roles } from '../../constants/enums';

const Header = ({ setSidebarOpen, isFromVetRoute, burgerRef }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const switcherRef = useRef();

  const { t } = useTranslation();
  const { user, logout } = useContext(AuthContext);
  const isPetOwner = user.role === Roles.PET_OWNER;
  const name = `${user.firstName} ${user.lastName}`;
  const userLabel = getInitialLetters(null, user.firstName, user.lastName);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const openHelpCenter = () =>
    window.open('https://kumba-help.freshdesk.com/support/home', '_blank');

  useOutsideClickDetector(switcherRef, closeMenu);

  return (
    <S.Header>
      <S.MenuIcon
        src={menu}
        alt="menu"
        ref={burgerRef}
        onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
      />
      <Link to="/">
        <S.LogoWrapper2>
          <S.Logo src={logo} alt="KUMBA" />
        </S.LogoWrapper2>
        <S.SmallLogo src={SmallLogo} alt="Kumba" onClick={() => setSidebarOpen(false)} />
      </Link>
      {isFromVetRoute && (
        <S.Content>
          <BreadCrumb role={user.role} />
        </S.Content>
      )}
      <S.ActionsNavigation>
        <S.ProfileWrapper ref={switcherRef}>
          <S.Profile onClick={() => setMenuOpen((open) => !open)}>
            <S.ProfileImage>
              <DynamicAvatar label={userLabel} />
            </S.ProfileImage>
            <S.Name>{name}</S.Name>
            <S.Vector src={arrow} alt="arrow" menuOpen={menuOpen}></S.Vector>
          </S.Profile>
          {menuOpen && (
            <S.ProfileMenu>
              {!isPetOwner && (
                <S.ListItem
                  onClick={openHelpCenter}
                  img={HelpCenterImage}
                  text={t('help.center')}
                />
              )}

              <S.ListItem
                onClick={() => {
                  logout(isFromVetRoute);
                }}
                img={LogOutImage}
                text={t('log.out')}
              />
            </S.ProfileMenu>
          )}
        </S.ProfileWrapper>
      </S.ActionsNavigation>
    </S.Header>
  );
};

export default Header;
