export { useOutsideClick } from './useOutsideClick';
export { parseTimeSlotHour } from './parseTimeSlotHour';
export { getAge } from './getAge';
export { attachSearchToGMap } from './attachSearchToGMap';
export * from './timeSlot';
export * from './stringToColor';
export * from './getVetName';
export * from './getTimeslotsInDate';
export * from './generateColors';

export const isValidAddress = (address = {}) => address.lat && address.lng;
