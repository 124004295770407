export const INTEGRATION_STATUS = {
  NEW: 'NEW',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED'
};
export const INTEGRATION_TYPE = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED'
};
export const INTEGRATION_MODAL = {
  CAREGIVERS_ATTENTION: 'CAREGIVERS_ATTENTION',
  CONNECT: 'CONNECT',
  CREDENTIALS_EDITOR: 'CREDENTIALS_EDITOR',
  DISABLE: 'DISABLE',
  EXTENSION: 'EXTENSION'
};
