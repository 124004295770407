import React from 'react';
import { Tooltip } from 'antd';
import { cssSpace } from '_fsd/shared';
const DISPLAY_WA_COUNT = 1;

const TimeSlotWorkingAreas = ({ timeSlot }) => {
  return (
    <div className="working-area-wrapper">
      {timeSlot?.workingAreas
        ?.slice(0, DISPLAY_WA_COUNT)
        .map((wa) => wa.name)
        .join(', ')}
      {timeSlot?.workingAreas?.length > DISPLAY_WA_COUNT && (
        <Tooltip
          placement="top"
          title={
            <div className={cssSpace.column}>
              {timeSlot?.workingAreas
                .slice(DISPLAY_WA_COUNT, timeSlot.workingAreas.length)
                .map(({ name }) => {
                  return <span key={`tooltipArea${name}`}>{name}</span>;
                })}
            </div>
          }>
          <div className="more-btn">{`+${timeSlot?.workingAreas?.length - DISPLAY_WA_COUNT}`}</div>
        </Tooltip>
      )}
    </div>
  );
};

export default TimeSlotWorkingAreas;
