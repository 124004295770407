import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { message, Popconfirm, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Dropdown, getVetName, cssText, Modal, ButtonColors, cssColor } from '_fsd/shared';
import { ReactComponent as IconRoute } from 'assets/icons/route.svg';

import {
  unlockRouteByIdMutation,
  excludeTimeSlotMutation,
  lockRouteByIdMutation,
  toggleIsLockedMutation,
  updateRouteStatusMutation
} from 'gql';

import { parseTimeSlotHour } from 'utils/helpers';
import TimeSlotWorkingAreas from 'components/timeslot-working-areas/TimeSlotWorkingAreas';
import css from '../../../pages/vet-clients/ui/outdate/Customers.module.css';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  MoreOutlined,
  CopyOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';

import { ReactComponent as RouteOpen } from 'assets/icons/route-open.svg';
import { ReactComponent as RouteLocked } from 'assets/icons/route-locked.svg';
import cls from 'classnames';
import ModalC from '../../../../components/timeslot-warning-modal/TimeslotWarningModal';
import { routeStatus } from '../../../../components/timeline/appointments/useTimelineAppointments';

export const ActionColumn = ({
  record,
  toggleIsLockedHandler,
  setUpdateTimeslotData,
  excludeTimeSlotHandler,
  setSelectedTimeslot,
  refetchTimeslots
}) => {
  const { t } = useTranslation();
  const [isLockedHovered, setIsLockedHovered] = useState(false);
  const [isUnLockedHovered, setIsUnLockedHovered] = useState(false);
  const [updateStatus] = useMutation(updateRouteStatusMutation, {
    refetchQueries: ['getTimeslots']
  });
  const [lockRoute] = useMutation(lockRouteByIdMutation);
  const [unlockRoute] = useMutation(unlockRouteByIdMutation);

  const route = record.routes?.[0];
  // todo: filter by selected date
  const warning = route?.status?.toLowerCase() === routeStatus.ADJUSTMENT && !record.isLocked;
  const isRegister = route?.status?.toLowerCase() === routeStatus.REGISTER;
  const isAdjustment = route?.status?.toLowerCase() === routeStatus.ADJUSTMENT;
  const isReady = route?.status?.toLowerCase() === routeStatus.READY;

  const isLocked = record?.isLocked;
  const tooltip = warning
    ? t('route.lock_warning')
    : isLocked
      ? t('route.unlock')
      : t('route.lock');

  const [modal, setModal] = useState(false);
  const [modalGenerate, setModalGenerate] = useState(false);
  const [modalLock, setModalLock] = useState(false);
  const [modalUnlock, setModalUnlock] = useState(false);

  const items = [
    isRegister
      ? {
          key: 0,
          label: (
            <div className={css.actionItem} onClick={() => setModalGenerate(true)}>
              <div className={css.actionColumnDropdown}>
                <IconRoute className="copy-icon" />
                <span className={cssText.s14w5h20i}>{t('vet_timeslots.generate_route')}</span>
              </div>
            </div>
          )
        }
      : undefined,
    isAdjustment
      ? {
          key: 1,
          label: (
            <div className={css.actionItem} onClick={() => setModalLock(true)}>
              <div className={css.actionColumnDropdown}>
                <CheckCircleOutlined className="copy-icon" />
                <span className={cssText.s14w5h20i}>{t('vet_timeslots.lock_route')}</span>
              </div>
            </div>
          )
        }
      : undefined,
    isReady
      ? {
          key: 2,
          label: (
            <div className={css.actionItem} onClick={() => setModalUnlock(true)}>
              <div className={css.actionColumnDropdown}>
                <CloseCircleOutlined className="copy-icon" />
                <span className={cssText.s14w5h20i}>{t('vet_timeslots.unlock_route')}</span>
              </div>
            </div>
          )
        }
      : undefined,
    {
      key: 10,
      label: (
        <div
          className={css.actionItem}
          onClick={() =>
            setUpdateTimeslotData({
              ...record,
              uid: null
            })
          }>
          <div className={css.actionColumnDropdown}>
            <CopyOutlined className="copy-icon" />
            <span className={cssText.s14w5h20i}>{t('vet_timeslots.copy_ts')}</span>
          </div>
        </div>
      )
    },
    {
      key: 11,
      label: (
        <div
          className={css.actionItem}
          onClick={() => {
            setSelectedTimeslot(record);
            setUpdateTimeslotData(record);
          }}>
          <div className={css.actionColumnDropdown}>
            <EditOutlined className="write-icon" />
            <span className={cssText.s14w5h20i}>{t('vet_timeslots.edit_ts')}</span>
          </div>
        </div>
      )
    },
    {
      key: 12,
      label: (
        <div className={css.actionItem}>
          <Popconfirm
            placement="top"
            title={t('are.you.sure.remove.timeslot')}
            okText="Yes"
            cancelText="No"
            onConfirm={() => excludeTimeSlotHandler(record.uid)}
            id="delete-popconfirm">
            <div className={cls(css.actionColumnDropdown, css.iconRemove)}>
              <DeleteOutlined className="trash-icon" color="red" />
              <span className={cssText.s14w5h20i}>{t('vet_timeslots.delete_ts')}</span>
            </div>
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <>
      <div className={css.actionColumn}>
        <Tag color="red" className={cls(css.tag, { [css.full]: record.isFull })}>
          {t('vet_timeslots.mark_full')}
        </Tag>
        <div className={css.lockIcon}>
          <Tooltip placement="top" title={tooltip} className={'timeslots-buttons-wrapper'}>
            <div className={cls('lock-icon container', css.lockIconContainer)}>
              {isLocked ? (
                isLockedHovered === record?.uid ? (
                  <RouteOpen
                    className="lock-icon locked"
                    onClick={() => {
                      if (isRegister || route?.status === undefined) {
                        toggleIsLockedHandler(record.uid);
                      } else {
                        setModal(true);
                      }
                    }}
                    onMouseLeave={() => setIsLockedHovered(false)}
                  />
                ) : (
                  <RouteLocked
                    onMouseEnter={() => setIsLockedHovered(record?.uid)}
                    onMouseLeave={() => setIsLockedHovered(false)}
                    className={cls('lock-icon locked', css.locked)}
                    onClick={() => setModal(true)}
                  />
                )
              ) : isUnLockedHovered === record?.uid ? (
                <RouteLocked
                  onMouseLeave={() => setIsUnLockedHovered(false)}
                  className={cls('lock-icon locked', css.locked)}
                  onClick={() => toggleIsLockedHandler(record.uid)}
                />
              ) : warning ? (
                <RouteOpen
                  className="lock-icon"
                  onClick={() => toggleIsLockedHandler(record.uid)}
                  onMouseEnter={() => setIsUnLockedHovered(record.uid)}
                  onMouseLeave={() => setIsUnLockedHovered(false)}
                />
              ) : (
                <RouteOpen
                  className="lock-icon"
                  onClick={() => toggleIsLockedHandler(record.uid)}
                  onMouseEnter={() => setIsUnLockedHovered(record.uid)}
                  onMouseLeave={() => setIsUnLockedHovered(false)}
                />
              )}
              {warning ? (
                <ExclamationCircleFilled
                  onMouseEnter={() => setIsUnLockedHovered(record.uid)}
                  onMouseLeave={() => setIsUnLockedHovered(false)}
                  className={cls('lock-icon warn', isUnLockedHovered && 'hide')}
                />
              ) : null}
            </div>
          </Tooltip>
        </div>
        <Dropdown
          menu={{ items }}
          className={css.dropDown}
          trigger={['click']}
          getPopupContainer={() => document.getElementById('ID_LAYOUT_SCROLLABLE')}>
          <MoreOutlined />
        </Dropdown>
      </div>
      {modal && (
        <ModalC
          onClose={() => setModal(false)}
          onConfirm={() => {
            setModal(false);
            toggleIsLockedHandler(record.uid);
          }}
        />
      )}
      {modalGenerate && route && isRegister && (
        <Modal
          className={css.generateModal}
          icon={<IconRoute className={css.iconRoute} />}
          isOpen={modalGenerate}
          title={t('vet_timeslots.generate_modal_title')}
          onClose={() => setModalGenerate(false)}
          footerProps={{ titleConfirm: t('vet_timeslots.confirm_generate') }}
          onConfirm={() => {
            setModalGenerate(false);
            updateStatus({
              variables: { record: { status: 'ADJUSTMENT', routeId: route.uid } }
            }).then(() => {
              message.success(t('vet_timeslots.message.generate_success'));
              refetchTimeslots();
            });
          }}>
          <span className={cls(cssText.s14h22w4, css.generateDescription)}>
            {t('vet_timeslots.generate_description')}
          </span>
        </Modal>
      )}
      {modalLock && route && isAdjustment && (
        <Modal
          className={css.lockModal}
          icon={<IconRoute className={css.iconRoute} />}
          isOpen={modalLock}
          title={t('vet_timeslots.lock_modal_title')}
          onClose={() => setModalLock(false)}
          footerProps={{
            titleConfirm: t('vet_timeslots.confirm_lock'),
            displaySecondaryConfirm: true,
            buttonSecondaryConfirmProps: {
              onClick: () => {
                setModalLock(false);
                lockRoute({
                  variables: { data: { routeId: route.uid, sendNotifications: false } }
                }).then(() => {
                  refetchTimeslots();
                  message.success(t('vet_timeslots.message.lock_success'));
                });
              },
              type: 'default',
              children: t('vet_timeslots.confirm_lock_secondary')
            }
          }}
          onConfirm={() => {
            setModalLock(false);
            lockRoute({
              variables: { data: { routeId: route.uid, sendNotifications: true } }
            }).then(() => {
              refetchTimeslots();
              message.success(t('vet_timeslots.message.lock_success'));
            });
          }}>
          <span className={cls(cssText.s14h22w4, css.generateDescription)}>
            {t('vet_timeslots.lock_description')}
          </span>
        </Modal>
      )}
      {modalUnlock && route && (
        <Modal
          className={css.generateModal}
          icon={<IconRoute className={css.iconRoute} />}
          isOpen={modalUnlock}
          title={t('vet_timeslots.unlock_modal_title')}
          onClose={() => setModalUnlock(false)}
          footerProps={{
            buttonConfirmProps: {
              type: 'default'
            },
            buttonCancelProps: {
              type: 'primary',
              colorScheme: ButtonColors.BLUE
            },
            titleConfirm: t('vet_timeslots.confirm_unlock')
          }}
          onConfirm={() => {
            setModalUnlock(false);
            unlockRoute({
              variables: { data: { routeId: route.uid } }
            }).then(() => {
              refetchTimeslots();
              message.success(t('vet_timeslots.message.unlock_success'));
            });
          }}>
          <span className={cls(cssText.s14h22w4, css.generateDescription)}>
            {t('vet_timeslots.unlock_description')}
          </span>
        </Modal>
      )}
    </>
  );
};

export const useTimeslots = (
  setUpdateTimeslotData,
  calendarDate,
  setSelectedTimeslot,
  refetchTimeslots
) => {
  const { t } = useTranslation();
  const [excludeTimeSlot] = useMutation(excludeTimeSlotMutation);
  const [toggleIsLocked] = useMutation(toggleIsLockedMutation);

  const toggleIsLockedHandler = async (id) => {
    try {
      await toggleIsLocked({
        variables: {
          id
        }
      });
      message.success(t('route.lock_message'));
      refetchTimeslots();
    } catch (err) {
      console.error(err);
    }
  };
  const excludeTimeSlotHandler = async (timeSlotId) => {
    try {
      const res = await excludeTimeSlot({
        variables: {
          record: {
            timeSlotId,
            date: calendarDate
          }
        }
      });

      if (res.data?.excludeTimeSlot === 'success') {
        message.success(t('vet.timeslots.exclude.success'));
        refetchTimeslots();
      } else {
        message.error(t('vet.timeslots.exclude.error'));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const timeslotsColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
      align: 'center',
      render: (text, record) => <div>{getVetName(record?.vet)}</div>
    },
    {
      title: 'Working Area',
      dataIndex: 'workingArea',
      key: 'timeslotWorkingHours',
      align: 'center',
      render: (text, record) => <TimeSlotWorkingAreas timeSlot={record} />
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'timeslotHours',
      align: 'center',
      render: (text, record) => (
        <div className="hours-wrapper">{`${parseTimeSlotHour(
          record.startTime
        )} - ${parseTimeSlotHour(record.endTime)}`}</div>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'timeslotActions',
      align: 'center',
      render: (text, record) => {
        return (
          <ActionColumn
            record={record}
            refetchTimeslots={refetchTimeslots}
            toggleIsLockedHandler={toggleIsLockedHandler}
            setUpdateTimeslotData={setUpdateTimeslotData}
            excludeTimeSlotHandler={excludeTimeSlotHandler}
            setSelectedTimeslot={setSelectedTimeslot}
          />
        );
      }
    }
  ];

  return { timeslotsColumn, toggleIsLockedHandler, excludeTimeSlotHandler };
};
