import styled, { css } from 'styled-components';

export const MenuItemCommonStyles = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const Sidebar = styled.aside`
  height: 100%;

  z-index: 10;
  background-color: ${({ theme }) => theme.colors.black};
  ::-webkit-scrollbar {
    width: 0.2rem;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #acbcd4;
    border-radius: 10px;
  }
  ${({ theme }) => theme.desktop`
    width: 20rem;
    `}
  ${({ theme }) => theme.mobile`
      transition: all .3s ease-in-out;
      position:absolute;
      transform: translateX(-100%);
      width: 0;
      overflow-y: auto;
      ${({ sidebarOpen }) =>
        sidebarOpen &&
        `
        width: 75%;
        transform: translateX(0);
      `}
    `}
`;

export const Navigation = styled.nav`
  width: 100%;
  min-height: 100%;
  padding: 1.6rem 0;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme }) => theme.mobile`
    padding: 0rem;
  `}
`;

export const MenuItemsWrapper = styled.ul`
  ${({ theme }) => theme.mobile`
    margin-top: 1.5rem;
  `}
`;

export const SubMenuItemsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 0;
  opacity: 0;
  box-sizing: unset;
  transition: 0.4s ease-in-out;
  padding-bottom: 1rem;
  background-color: ${({ isPetsMenuActive }) =>
    isPetsMenuActive ? 'rgba(0, 0, 0, 0.16)' : 'rgba(50, 59, 75, 1)'};
  margin-bottom: 1.6rem;
  ${({ subMenuVisible }) => subMenuVisible && `height: unset`};
  ${({ subMenuVisible }) => subMenuVisible && `opacity: 1`};
`;

export const Loaders = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
`;

export const Loader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const LoaderCircle = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background: rgba(143, 147, 156, 0.4);
  border-radius: 50%;
  margin-left: 2.4rem;
`;

export const LoaderLine = styled.div`
  width: 11.6rem;
  height: 1.6rem;
  background: rgba(143, 147, 156, 0.4);
  border-radius: 0.4rem;
  margin-left: 2.4rem;
  ${({ theme }) => theme.laptop`
    width: 9.5rem;
  `}
`;

export const MenuItem = styled.li`
  min-height: 6.4rem;
  margin-bottom: ${({ pets }) => (pets ? '0rem' : '1.6rem')};

  ${MenuItemCommonStyles}

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  & h2 {
    opacity: 0.64;
  }

  ${({ theme }) => theme.mobile`
  margin-bottom: 0rem;
  `}
`;

export const MenuItemExtendable = styled(MenuItem)`
  background-color: rgba(0, 0, 0, 0.08);
`;

export const ExpandIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  transition: 0.2s ease-in-out;
  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(180deg);
    `};
`;

export const SubMenuItem = styled.li`
  height: 4rem;
  padding-left: 2.4rem;

  ${MenuItemCommonStyles}
`;

export const IconWrapper = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserInfoAndNavWrapper = styled.div``;

export const UserInformation = styled.div`
  height: 16.6rem;
  border-bottom: 0.05rem solid rgba(255, 255, 255, 0.8);
  padding-left: 2.4rem;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-family: inter, assistant;
  font-weight: 500;
`;
export const HowItWorksWrapper = styled.div`
  padding-left: 2.4rem;
  margin-bottom: 3.6rem;
  margin-top: 2.4rem;
  ${({ theme }) => theme.mobile`
    padding: 0 1.6rem;    
  `}
`;

export const UserImage = styled.div`
  height: 7.6rem;
  width: 7.6rem;
  margin-top: 2rem;
  margin-bottom: 1.8rem;
  cursor: pointer;
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const UserName = styled.div`
  cursor: pointer;
`;

export const Help = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.4rem;
  padding-left: 1.6rem;
  padding-right: 2rem;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  svg {
    fill: ${({ theme }) => theme.colors.white};
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.6rem;
    ${({ theme }) => theme.laptop`
      min-width: 2.8rem;
      width: 2.8rem;
      height: 2.8rem;
    `}
  }
  > h2 {
    font-family: inter, assistant;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: rgba(255, 255, 255, 0.72);
  }
`;

export const Footer = styled.div`
  width: 15.1rem;
  //height: 16rem;
  padding: 0.8rem;
  gap: 1.6rem;
  margin-left: 1.5rem;
`;

export const FooterTitle = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const StoresWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 13.5rem;
  height: 8.8rem;
  && svg {
    margin-left: 0;
  }
`;
