import React from 'react';
import { cssText } from '_fsd/shared';
import { Select } from 'antd';
import css from './WorkingAreasOrders.module.css';
import { useTranslation } from 'react-i18next';

export const WorkingAreasOrders = ({ filters, setFilters }) => {
  const { t } = useTranslation();

  return (
    <div className={css.controls}>
      <span className={cssText.s14w4l24}>{t('settings_page.service_area.areas.sort_by')}</span>
      <Select
        size="small"
        onChange={(order) => setFilters({ order })}
        className={css.selectOrder}
        defaultValue={filters.order}>
        <Select.Option selected value="createdAt">
          {t('settings_page.service_area.areas.order_created_at')}
        </Select.Option>
        <Select.Option value="updatedAt">
          {t('settings_page.service_area.areas.order_updated_at')}
        </Select.Option>
        <Select.Option value="name">
          {t('settings_page.service_area.areas.order_name')}
        </Select.Option>
      </Select>
    </div>
  );
};
