import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Steps, Input } from 'antd';

import Button from 'components/common/button/Button';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-white-line.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const ModalHeader = ({
  className,
  search,
  current,
  changeStep,
  setSearch,
  openNewClientModal,
  onRequestClose
}) => {
  const { Step } = Steps;
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: `(max-width: 400px)`
  });
  return (
    <div className={className}>
      <div className="appointments-stepper-wrapper">
        <Steps current={current} direction={isMobile ? 'vertical' : 'horizontal'}>
          <Step
            title={t('admin.recommender.new_appointment.stepper_first_title')}
            description=""
            onClick={() => changeStep(0)}
          />
          <Step
            title={t('admin.recommender.new_appointment.stepper_second_title')}
            description=""
            onClick={() => changeStep(0)}
          />
          <Step
            title={t('admin.recommender.new_appointment.stepper_third_title')}
            subTitle=""
            description=""
            onClick={() => changeStep(1)}
          />
        </Steps>
        <CloseIcon onClick={onRequestClose} />
      </div>
      {current === 0 && (
        <div className="appointments-header-wrapper">
          <>
            <Input
              placeholder={t('admin.recommender.new_appointment.search_placeholder')}
              prefix={<SearchOutlined />}
              className="search-input"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              label={t('admin.recommender.new_appointment.new_client')}
              icon={<PlusIcon />}
              className="appointment-btn"
              onClick={openNewClientModal}
            />
          </>
        </div>
      )}
    </div>
  );
};

export default ModalHeader;
