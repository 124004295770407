import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AREA_TYPE } from '_fsd/entities/working-area';
import { uniqueId } from 'lodash';

const schema = Yup.object({
  type: Yup.string().oneOf(Object.values(AREA_TYPE)).required(),
  name: Yup.string().required(),
  thumbnail: Yup.string().nullable(),
  zipCodes: Yup.array()
    .of(
      Yup.object({
        uid: Yup.string().required(),
        zip: Yup.string().required()
      })
    )
    .when('type', {
      is: AREA_TYPE.ZIP,
      then: (schema) => schema.min(1).required(),
      otherwise: (schema) => schema
    }),
  polygons: Yup.array()
    .of(
      Yup.object({
        uid: Yup.string(),
        name: Yup.string().required(),
        area: Yup.array().of(
          Yup.object({
            lat: Yup.number().required(),
            lng: Yup.number().required()
          })
        )
      })
    )
    .when('type', {
      is: AREA_TYPE.POLYGON,
      then: (schema) => schema.min(1).required(),
      otherwise: (schema) => schema
    })
});

const getInitialValues = (data) => {
  if (typeof data === 'boolean') {
    return {
      name: '',
      type: AREA_TYPE.POLYGON,
      polygons: [],
      zipCodes: [],
      thumbnail: undefined
    };
  }
  return {
    uid: data.uid,
    name: data.name,
    type: data.type,
    polygons:
      data.polygons?.map((p) => {
        const key = uniqueId('Polygon ');
        return {
          ...p,
          key: key,
          name: p.name || key
        };
      }) || [],
    zipCodes: data.zipCodes || [],
    thumbnail: data.thumbnail
  };
};
export const useWorkingAreaForm = (data, formikProps = {}) => {
  return useFormik({
    validationSchema: schema,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
    initialValues: getInitialValues(data),
    ...formikProps
  });
};
