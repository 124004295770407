import { AREA_TYPE } from '../types';

const removeProperty = (key) => (object) => {
  delete object[key];
  return object;
};

export const serializeWorkingArea = (workingArea) => {
  return {
    ...workingArea,
    polygons:
      workingArea.type === AREA_TYPE.POLYGON ? workingArea.polygons.map(removeProperty('key')) : [],
    zipCodes:
      workingArea.type === AREA_TYPE.ZIP ? workingArea.zipCodes.map(removeProperty('key')) : []
  };
};
