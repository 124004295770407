import axios from 'axios';
export const getZipArea = async (zips = []) => {
  const data = await axios
    .request({
      method: 'GET',
      url: 'https://vanitysoft-boundaries-io-v1.p.rapidapi.com/rest/v1/public/boundary/zipcode',
      headers: {
        'X-RapidAPI-Key': 'f5ff011233mshe5e8aedfa79db92p1698f1jsn5ce0818e1095',
        'X-RapidAPI-Host': 'vanitysoft-boundaries-io-v1.p.rapidapi.com'
      },
      params: {
        zipcode: zips.join(',')
      }
    })
    .catch((error) => console.log('Error getting zip code boundries', error));
  return data?.data?.features?.map((feat) => {
    return {
      zip: feat.properties.zipCode?.replace(/^0+|$/g, ''),
      polygons: feat.geometry.coordinates.map((polygon) => {
        const mapOver = polygon[0][0]?.length ? polygon[0] : polygon;
        return mapOver.map((k) => ({
          lat: k[1],
          lng: k[0]
        }));
      })
    };
  });
};
