import styled from "styled-components";

import { Link as L } from 'react-router-dom';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 64rem;
  height: 100%;
  padding-top: 1rem;
  ${({ theme }) => theme.mobile`
    padding: 40px 3.2rem;
  `}
  padding-top: 12rem;
  padding-bottom: 12rem;
`;
export const Container = styled.div`
  padding-top: 18rem;
  ${({ theme }) => theme.mobile`
    padding: 0;
    padding-top: 30px;
  `}
`;

export const Content = styled.div``;

export const BottomSection = styled.div`
  margin-top: ${({ isForModal }) => (isForModal ? '1.6rem' : '2rem')};
`;

export const Question = styled.span`
  font-family: Inter;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: #b0b7c3;
  margin-right: 0.5rem;
  ${({ theme }) => theme.mobile`
      font-size: ${({ isForModal }) => (isForModal ? '1.2rem' : '1.6rem')}
 `}
`;

export const Link = styled(L)`
  font-family: inter, assistant;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: #26acb9;
`;
