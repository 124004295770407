import React from 'react';
import cls from 'classnames';
import css from './Section.module.css';

export const Section = ({ children, label, visible = true }) => (
  <div className={cls(css.section, { [css.visible]: visible })}>
    <div className={css.label}>{label}</div>
    <div className={css.container}>{children}</div>
  </div>
);
