import { gql } from 'graphql.macro';
import { useMutation } from '@apollo/client';

export const useUpdateIntegration = (options) => {
  return useMutation(query, {
    ...options,
    refetchQueries: ['integrations', ...(options?.refetchQueries || [])]
  });
};

const query = gql`
  mutation updateIntegration($record: UpdateIntegrationInput!) {
    integration: updateIntegration(updateIntegrationInput: $record) {
      uid
    }
  }
`;
