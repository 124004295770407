import React, { useEffect, useState, memo } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

import { InfoCircleFilled } from '@ant-design/icons';

import AddressChecklist from './AddressChecklist';
import locationIcon from './locationIcon';
import googleIcon from 'assets/images/google-powered.png';
import { Wrapper, DropDownContainer, Option, GoogleWrapper, GoogleImage } from './components';
import './styles.css';

const AddressAutocomplete = ({
  onBlur,
  address,
  onChange,
  onSelect,
  disabled = false,
  label,
  placeholder,
  formikAddress,
  setIsAddressValid,
  isChecklistNeeded,
  required,
  name,
  infoText,
  index,
  isForPreference,
  className,
  customErrorMessage
}) => {
  const { t } = useTranslation();
  const [placeId, setPlaceId] = useState(null);

  useEffect(() => {
    if (placeId) {
      geocodeByPlaceId(placeId).then((res) => {
        getLatLng(res[0]).then(({ lat, lng }) => {
          const addr = {
            countryCode: '',
            city: '',
            street: '',
            houseNumber: '',
            zipCode: '',
            description: res[0]?.formatted_address ?? address,
            lat: lat,
            lng: lng
          };
          for (const component of res[0].address_components) {
            const componentType = component.types[0];

            switch (componentType) {
              case 'street_number': {
                addr.houseNumber = component.short_name;
                break;
              }

              case 'route': {
                addr.street = component.long_name;
                break;
              }

              case 'postal_code': {
                addr.zipCode = component.long_name;
                break;
              }

              case 'locality':
                addr.city = component.long_name;
                break;

              case 'country':
                addr.countryCode = component.short_name;
                break;
              default: {
                break;
              }
            }
          }
          onSelect(addr, name, index);
        });
      });
    }
  }, [placeId]);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={(val) => onChange(val, index)}
      onSelect={(address, placeId) => {
        onChange(address, index);
        setPlaceId(placeId);
      }}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <Wrapper isForPreference={isForPreference}>
          <Form.Item hidden name="address"></Form.Item>
          <Form.Item hidden name="workingAreas"></Form.Item>
          <Form.Item
            style={{ width: '100%' }}
            required={required}
            label={
              label && (
                <>
                  {label}
                  {infoText && (
                    <Tooltip title={infoText}>
                      <InfoCircleFilled className="address-info-icon" />
                    </Tooltip>
                  )}{' '}
                </>
              )
            }
            rules={[{ required: required, message: t('field.is.required') }]}
            name={name}>
            <Input
              {...getInputProps({
                placeholder: placeholder,
                className: `location-search-input ${className}`
              })}
              size="small"
              placeholder={placeholder}
              suffix={locationIcon}
              disabled={disabled}
            />
            {customErrorMessage && (
              <span className="custom-error-message">{t('error.address.dropdown')}</span>
            )}
          </Form.Item>
          <DropDownContainer>
            {suggestions.map((suggestion) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer'
                  }
                : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer'
                  };
              return (
                <div
                  key={suggestion.uid}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style
                  })}>
                  <Option>{suggestion.description}</Option>
                </div>
              );
            })}

            {!!suggestions.length && (
              <GoogleWrapper>
                <GoogleImage src={googleIcon} alt="google" />
              </GoogleWrapper>
            )}
          </DropDownContainer>

          {isChecklistNeeded && (
            <AddressChecklist formikAddress={formikAddress} setIsAddressValid={setIsAddressValid} />
          )}
        </Wrapper>
      )}
    </PlacesAutocomplete>
  );
};

export default memo(AddressAutocomplete);
