import { gql } from 'graphql.macro';
import { useQuery } from '@apollo/client';

export const useIntegrations = (variables = {}) => {
  const { data, ...rest } = useQuery(query, {
    variables,
    fetchPolicy: 'no-cache'
  });
  return { data: data?.integrations, ...rest };
};

const query = gql`
  query integrations {
    integrations {
      uid
      status
      pim {
        uid
        name
        template
      }
      createdAt
      updatedAt
    }
  }
`;
