import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
export const ContentWrapper = styled.div`
  text-align: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.8rem;
`;
export const FooterText = styled.h3`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.45);
  opacity: 0.8;
  margin: 1rem 0.8rem 0;
`;
export const FooterImg = styled.img``;
export const MessageWrapper = styled.div`
  width: 34rem;
  //height: 47.6rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.4rem 4.5rem -3.1rem rgba(45, 28, 28, 0.08);
  border-radius: 0.4rem;
  padding: 2.4rem 2.4rem 3.2rem;
  text-align: center;
`;
export const CheckboxImg = styled.img`
  width: 4rem;
  height: 4rem;
`;
export const MessageTitle = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: rgba(0, 0, 0, 0.85);
  margin: 1.6rem 0;
`;
export const ProfileImg = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  margin-bottom: 0.8rem;
  object-fit: cover;
`;

export const MessageDetailsWrapper = styled.div`
  box-shadow: inset 0 0.1rem 0 #f0f0f0;
  padding-top: 1.6rem;
`;
export const MessageRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;
export const MessageKey = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: rgba(0, 0, 0, 0.45);
`;
export const MessageValue = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.85);
`;
export const PetsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 1rem;
`;
export const PetItem = styled.div`
  height: 2.2rem;
  padding: 0.1rem 0.8rem;
  display: flex;
  align-items: center;
  margin-right: 0.4rem;
  justify-content: space-between;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 0.2rem;
  cursor: pointer;
  margin-bottom: 0.4rem;
`;
export const PetName = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.85);
`;

export const PetType = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.45);
  margin-left: 1rem;
`;

export const Button = styled.a`
  width: 100%;
  height: 4rem;
  background: #ff9a05;
  border: 1px solid #ff9a05;
  box-sizing: border-box;
  box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.043);
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
  cursor: pointer;
`;

export const ButtonText = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const MessageText = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.45);
`;

export const Email = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.85);
`;

export const VetName = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 1.6rem;
`;
