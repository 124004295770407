import { gql } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { AREA_TYPE } from '../types';

/* variables: { record: { name }, skip, take, order, isSaved } */
/* interface for return object { data: {data, total} } */
export const useFindWorkingAreas = (variables) => {
  return useQuery(query, {
    variables,
    fetchPolicy: 'no-cache'
  });
};

/* variables: { record: { name }, skip, take, order, isSaved } */
/* interface for return object { data: {data, total} } */
export const useFindWorkingAreasInfinityScroll = (variables) => {
  const [data, setData] = useState();
  const [total, setTotal] = useState();
  const queryResult = useQuery(query, {
    variables: {
      ...variables,
      order: {
        [variables.order]: variables.order === 'name' ? 'ASC' : 'DESC'
      }
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!queryResult.loading && queryResult.data?.data) {
      setData((prev) =>
        variables.skip > 0 ? [...(prev || []), ...queryResult.data.data] : queryResult.data.data
      );
    }
  }, [queryResult.loading, queryResult.data?.data]);

  useEffect(() => {
    if (!queryResult?.loading) {
      setTotal(queryResult?.data?.total);
    }
  }, [queryResult?.data?.total, queryResult?.loading]);

  return {
    data: { data, total },
    loading: queryResult.loading
  };
};

const query = gql`
  query findWorkingAreas(
    $record: FindWorkingAreasRecord!
    $isSaved: Boolean
    $take: Int
    $skip: Int
    $order: OrderWorkingAreas
  ) {
    data: findWorkingAreas(
      record: $record
      isSaved: $isSaved
      take: $take
      skip: $skip
      order: $order
    ) {
      uid
      createdAt
      updatedAt
      name
      type
      thumbnail
      isSaved
      vetAdmin {
        uid
      }
      polygons {
        uid
        name
        area
      }
      zipCodes {
        uid
        zip
      }
    }
    total: countFindAll(record: $record, isSaved: $isSaved)
  }
`;
