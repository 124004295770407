import moment from 'moment';
import { routeStatus } from 'components/timeline/appointments/useTimelineAppointments';

export const DEFAULT_ESTIMATED_TRAVELING_TIME = 20;
const KM_TO_MILES_CONVERSION = 0.621371;

export const getRouteSummaryData = (appointmentRoutes, timeSlot, route) => {
  const totalDistanceInMiles = Number(
    (
      (appointmentRoutes ?? []).reduce(
        (acc, appointmentRoute) => (appointmentRoute?.travelingDistance || 0) + acc,
        0
      ) * KM_TO_MILES_CONVERSION
    ).toFixed(2)
  );

  const calculateTotalTravelingTime = (appointmentRoutes) => {
    return Number(
      (appointmentRoutes ?? [])
        .reduce((acc, appointmentRoute) => (appointmentRoute?.travelingTime || 0) + acc, 0)
        .toFixed()
    );
  };

  const calculateDefaultEstimatedTravelingTime = (appointmentRoutes, defaultTime) => {
    return appointmentRoutes?.length * defaultTime || 0;
  };

  const routingTime =
    route?.status.toLowerCase() === routeStatus.REGISTER
      ? calculateDefaultEstimatedTravelingTime(appointmentRoutes, DEFAULT_ESTIMATED_TRAVELING_TIME)
      : calculateTotalTravelingTime(appointmentRoutes);

  const appointmentsTime = Number(
    (appointmentRoutes ?? [])
      .reduce((acc, appointmentRoute) => (appointmentRoute?.duration || 0) + acc, 0)
      .toFixed()
  );

  const totalTime = routingTime + appointmentsTime;

  const startTime = moment(timeSlot.startTime, 'HH:mm:ss');
  const endTime = moment(timeSlot.endTime, 'HH:mm:ss');
  const totalTimeSlotDuration = endTime.diff(startTime, 'minutes');

  const freeTime = totalTimeSlotDuration - Math.abs(totalTime);

  const percentage = Math.abs((freeTime / totalTimeSlotDuration) * 100);

  return [totalDistanceInMiles, totalTime, routingTime, appointmentsTime, freeTime, percentage];
};
