import { useMutation } from '@apollo/react-hooks';
import { removePetMutation } from 'gql';

const useRemovePet = () => {
  const [removePet] = useMutation(removePetMutation, {
    refetchQueries: ['getPetsByUser']
  });
  const handleRemovePet = async (uid) => {
    try {
      await removePet({
        variables: {
          record: {
            uid
          }
        },
        refetchQueries: ['getPetOwners', 'getPetOwnersTotals', 'getPetsByUser']
      });
    } catch (e) {
      console.log(e);
    }
  };

  return { handleRemovePet };
};

export default useRemovePet;
